import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import download_file from '../../assets/svg/download-file.svg'
import ErrorResults from '../TableResults/ErrorResults';
import Pagination from '../pagination/Pagination';

function ErrorList() {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <div>
       <Button className="p-0 d-flex align-items-center" variant="" onClick={handleShow}>
       <i className='edu ic-error-fill me-1'></i><span className='text-danger text-decoration-underline fs-8 fw-medium'>8 errors found</span>
      </Button>


      <Modal centered size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
       <div className=''>
       <Modal.Title className='fs-5 fw-semibold'>Customers Data</Modal.Title>
       <i className='edu ic-error-fill me-1'></i><span className='text-danger text-decoration-underline fs-8 fw-medium'>8 errors found</span>
       </div>
       <Button variant=''><img src = {download_file}/></Button>
       </div>
        </Modal.Header>
        <Modal.Body>
           <ErrorResults/>
           <Pagination/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ErrorList
