import {React, useEffect, useState } from "react";
import PlatformTableList from './PlatformTableList'
import PlatformTableResult from '../../components/table/PlatformTableResult'
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


const Office360 = (props) => { 

  const [tablesList, setTablesList] = useState([]);
  const [viewTable, setViewTable] = useState();
  const BaseUrl = GetBaseUrl()[0]   ;
  var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");  


  useEffect(() => { 

          axios
          .get( BaseUrl  +   '/Platform/GetPlatformAPIs/1', {
              headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
              },
          })
          .then((res) => {
            setTablesList(res.data )
          }).finally(() => {}) ;
         
  }, [])
 
  const renderItem = (item, index) =>    <div className='fw-semibold' key={index}>{item}</div>   

  useEffect(() => {
   // console.log('Table Id: '+viewTable)
  }, [viewTable]);


 
return (
    <div>
      <div className = "rounded-5 main-bg p-35 min-vh-85">
            <div className='row'>
                    <div className = "col-lg-4 col-xl-3 px-0 pe-3 table-scroll">
                        <div className = "top-bg p-3 rounded-5 min-vh-100">
                                <div className="row d-flex flex-column h-100">
                                    <div className="col-6 w-100">
                                                <PlatformTableList 
                                                renderItem={renderItem}
                                                tablesList={tablesList}
                                                setViewTable={setViewTable}
                                                IsViewTable={true}
                                                PlatformType = 'Office365'
                                                IsPlatformTable = '1'
                                                />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className = "col-lg-8 col-xl-9 px-0 ">
                        <div className = "top-bg rounded-5 min-vh-100">
                            {viewTable  ?
                            <PlatformTableResult
                            PlatformTableId={viewTable}
                            setViewTable={setViewTable}
                            HeaderType='ViewPlatformTable'
                            PlatformTable = {true}
                            ConnectionId = {props.ConnectionId}
                            />
                            :
                            null}
                        </div>
                    </div>
            </div>
      </div>
    </div>
)
}
export default Office360
