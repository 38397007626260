import React, { useState } from 'react'
import { Button, ButtonGroup, ToggleButton, Dropdown } from 'react-bootstrap'
import img from '../../assets/svg/img.svg'
import pdf from '../../assets/svg/pdf.svg'

function ReportTitle() {
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Custom', value: '1',  },
        { name: 'Yesterday', value: '2' },
        { name: '7D', value: '3' },
        { name: '30D', value: '4' },
        { name: '3M', value: '5' },
        { name: '6M', value: '6' },
        { name: '12M', value: '7' },
        { name: 'Today', value: '8' },
      ];


  return (
    <div className='d-flex justify-content-between align-content-center mb-4'>
      <div className='d-flex align-items-center'>
      <Button variant='' className='p-0 mb-0 mt-1'><i className='edu ic-left me-2'/></Button>
      <span className='fw-semibold fs-5'>🎯 School KPIs</span>
      </div>
      <div className='d-flex align-items-center justify-content-end'>
      <Button variant='' className='main-button rounded-pill fw-medium fs-8 ms-2'>Edit Report</Button>
        <Dropdown>
            <Dropdown.Toggle variant='' id="dropdown-basic" className='rounded-pill main-button px-3 fw-medium mx-2 fs-8'>
                Export
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" className='p-1 ps-3'><img className='mx-2' src = {img}/> Image</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-2" className='p-1 ps-3'><img className='mx-2' src = {pdf}/> Pdf</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    </div>
    </div>
  )
}

export default ReportTitle
