import {React, useEffect, useState, useRef } from "react";
import PublicTable from '../../components/API_Table/main'
import GetBaseUrl from '../../functions/SetBaseUrl'


import axios from "axios";

const TableResult = ({TableId,  tableRowsDefault, setViewDataFieldsTable, setViewTable, OnlyErrorRows,HeaderType, DownloadExcel,tableRef}) => {




  const BaseUrl = GetBaseUrl()[0]   ;
  const [tableHead, setTableHead] = useState(['']);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState('')
  const [tableName, setTableName] = useState('')
  var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");


     
useEffect(() => {

  if(OnlyErrorRows){
     let tableHead = [''];
     let tablebodyCol = [];
 
     setTAbleUrl(BaseUrl  +  '/Data/GetErrorDataByTable/'+ TableId)
     axios
     .get( BaseUrl  +  '/Data/GetErrorDataByTable/'+ TableId, {
         headers: {
         Authorization: `Bearer ${token}`,
         "content-type": "application/json",
         },
     })
     .then((res) => {
       setTableName(res.data.tableName)
       res.data.columns.map((item, index) => tableHead.push( item['columnName'] )     )
       res.data.columns.map((item, index) => tablebodyCol.push( 'rowData' )               )
     }).finally(() =>{

       setTableHead(tableHead) 
       setBodyCol(tablebodyCol) 
     });

  }

  
}, [])










return (
<>  
{tableUrl
  ?
    <PublicTable
    loadingText='Loading Table'
    TableTitle={tableName}
    TableId= {TableId}  
    TableUrl= {tableUrl}  
    TableHead= {tableHead}      
    BodyCol=  {bodyCol}
    lastColumn={false}
    withOutBorder={false}
    defaultRows={tableRowsDefault ? tableRowsDefault : '10'}
    withHeader ={HeaderType}
    firstColumn 
    Internal ={true}

    setViewDataFieldsTable={setViewDataFieldsTable}
    setViewTable={setViewTable}
    DownloadExcel={DownloadExcel}
    tableRef={tableRef}

   
    />
  :
null
}

  
  </>

  )
}

export default TableResult
