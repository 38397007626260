import React, { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import back from "../assets/svg/back.svg";
import upload from "../assets/svg/upload_mapping.svg";
import plus from "../assets/svg/add_condition.svg";
import add from "../assets/svg/add-value.svg";
import reset from "../assets/svg/retry-white.svg";
import Delete from "./modals/DeleteReport";
import AddValue from "./modals/AddValue";

function ConditionalFields(props) {
  return (
    <div>
      <div className="d-flex justify-content-between mb-1">
        <div className="d-flex align-items-center">
          <Button
            className="ps-0 py-0 d-flex align-items-center"
            variant=""
            onClick={() => {
              props.setViewConditionalFormatting(false);
              props.setViewDataFieldPropTable(true);
            }}
          >
            <img src={back} style={{ width: "20px" }} />
            <span className="ms-2 fs-5 fw-semibold">
              Add Conditional Formatting
            </span>
          </Button>
        </div>
        <div className="">
          <Button
            variant=""
            className="btn-secondary me-2 fw-semibold rounded-pill py-2"
            onClick={() => {
              props.setViewConditionalFormatting(true);
              props.setViewDataFieldPropTable(false);
            }}
          >
            Discard
          </Button>
          <Button
            variant=""
            className="main-button fw-semibold rounded-pill py-2"
          >
            Save
          </Button>
        </div>
      </div>

      <div className="my-3 ">
        <div className="d-flex align-items-center">
          <span className="fw-semibold mt-2 me-2 w-5">If</span>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Column Name
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Column 1</option>
                <option>Column 2</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Operator
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Yes</option>
                <option>No</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-25 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Value</Form.Label>
              <Form.Control
                className="field-input"
                type="text"
                placeholder="Enter Value"
              />
            </Form.Group>
          </div>
          <span className="fw-semibold mt-2 mx-2">Then</span>
          <div className="w-25 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Actions</Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Mapping</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="">
            <Dropdown className="">
              <Dropdown.Toggle variant="" id="dropdown-basic" className="mt-3">
                <i className="edu ic-more ifmore fs-5" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-1 ps-3 fw-medium">
                  <i className="edu ic-refresh me-2" /> Reset Fields
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="p-1 ps-3 fw-medium me-2">
                  <i className="edu ic-delete me-2" /> Delete Condition
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div></div>
        </div>
        <div className="d-flex align-items-center">
          <span className="fw-semibold mt-2 me-2 w-5"></span>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Required
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Yes</option>
                <option>No</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-20 mx-2 d-flex ">
            {" "}
            <Button variant="" className=" d-flex align-items-center mt-2 px-0">
              <img src={upload} />
              <span className="ps-2 fw-semibold fs-7 text-start">
                Upload file with accepted values
              </span>
              <Form.Control
                className="field-input MappingFile hide"
                type="file"
                placeholder="Enter Values"
              />
            </Button>
          </div>
          <div className="w-25 mx-2 ">
            <div className="">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fw-semibold mb-0 fs-7">
                  Add value manually
                </Form.Label>
                <div className="inputWithButton">
                  <Form.Control
                    className="field-input"
                    type="text"
                    placeholder="Enter Values"
                  />
                  <AddValue />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="w-30 mx-4 "></div>

          <div></div>
        </div>
      </div>

      <div className="py-3 border-top ">
        <div className="d-flex align-items-center">
          <span className="fw-semibold mt-2 me-2 w-5">Else If</span>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Column Name
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Column 1</option>
                <option>Column 2</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Operator
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Yes</option>
                <option>No</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-25 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Value</Form.Label>
              <Form.Control
                className="field-input"
                type="text"
                placeholder="Enter Value"
              />
            </Form.Group>
          </div>
          <span className="fw-semibold mt-2 mx-2">Then</span>
          <div className="w-25 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Actions</Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Mapping</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="">
            <Dropdown className="">
              <Dropdown.Toggle variant="" id="dropdown-basic" className="mt-3">
                <i className="edu ic-more ifmore fs-5" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-1 ps-3 fw-medium">
                  <i className="edu ic-refresh me-2" /> Reset Fields
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="p-1 ps-3 fw-medium me-2">
                  <i className="edu ic-delete me-2" /> Delete Condition
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div></div>
        </div>
      </div>
      <div className="w-100">
        <hr className="w-100" />
        <Button
          variant=""
          className="main-button fw-semibold rounded-pill w-12 condition"
        >
          <img src={plus} /> Condition
        </Button>
      </div>
    </div>
  );
}

export default ConditionalFields;
