  //----------------------------------------------------
 export function FindMultipleInObj(array, Key1, Value1, Key2='test', Value2='test') {
    let res = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key1] === Value1 && array[i][Key2] === Value2) {
        //array.splice(i, 1);
        res = true;
        break;
      }
    }
    return res;
  }
  //----------------------------------------------------
 export function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }

  //----------------------------------------------------
 export function FindIndexInObj(array, Key, Value) {
    var msg = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i ;
        break;
      }
    }
    return msg;
  }
    //----------------------------------------------------
 export function FindDeleteInObj(array, Key, Value) {
    //var msg = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
       // msg = i + 1;
       array.splice(i,1)
        break;
      }
    }
   // return msg;
  }
  //----------------------------------------------
 export function RandomNumberInRange(min, max) {
    //  get number between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }