import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";

function Rename(props) {
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [newName, setNewName] = useState(props.Title);
  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  function handleRename(ReportId, newName) {
    const Json = { reportId: ReportId, newName: newName };
    // console.log(BaseUrl + "/Report/RenameReport", Json);
    axios
      .post(BaseUrl + "/Report/RenameReport", Json, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Report name has been updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
        props.setViewTable(ReportId);
        props.setReload(Math.random(200));
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  return (
    <div>
      <Button
        className="p-0 px-0 fs-7 w-100 d-flex align-items-center"
        variant=""
        onClick={handleShow}
      >
        <i className="edu ic-rename me-2"> </i>Rename
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="p-3 pb-0" closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold">Rename</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7">Report Name</Form.Label>
            <Form.Control
              className="field-input"
              type="text"
              placeholder="Enter Table Name"
              value={newName}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="main-button w-100 fw-semibold rounded-pill py-2"
            variant=""
            onClick={() => handleRename(props.ReportId, newName)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Rename;
