import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import upload from "../../assets/svg/upload.svg";
import ChooseDirectory from "./ChooseDirectory";
import "./modal.css";
import DragDropFile from "../../components/UploadDocs/DragDropFile";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

function UpdateTable(props) {
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");

  const [ch1, setCh1] = useState(true);
  const [ch2, setCh2] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [updateType, setUpdateType] = useState("OverrideUploadFile");

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    //   console.log(updateType);
  }, [updateType]);

  const handleChange = (e) => {
    let name = e.target.name;
    if (name === "over") {
      setUpdateType("OverrideUploadFile");
      setCh1(true);
      setCh2(false);
    } else {
      setUpdateType("AddOnUploadFile");
      setCh1(false);
      setCh2(true);
    }
  };

  return (
    <div>
      <Button
        className="p-0 fs-7 mt-2 w-100 d-flex align-items-center j"
        variant=""
        onClick={handleShow}
      >
        <i className="edu ic-upload"> </i>
        <span className="mx-1">Update</span>
      </Button>

      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header className="p-3 pb-0" closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold">Update Table</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            {["radio"].map((type) => (
              <div key={`default-${type}`} className="mb-3 d-flex">
                <Form.Check // prettier-ignore
                  type={type}
                  id={`default-${type}`}
                  label={`Overwrite Data`}
                  name="over"
                  onClick={handleChange}
                  defaultChecked={ch1}
                />

                <Form.Check // prettier-ignore
                  type={type}
                  label={`Add-on Data`}
                  className="mx-2"
                  name="add"
                  onClick={handleChange}
                  defaultChecked={ch2}
                />
              </div>
            ))}
          </div>

          <DragDropFile
            NeedConfirmUpload
            TableId={props.TableId}
            Update
            UpdateType={updateType}
            confirmed={confirmed}
            setConfirmed={setConfirmed}
            setShow={setShow}
            setViewTable={props.setViewTable}
            noDirectory
          />
        </Modal.Body>
        <Modal.Footer className="border-0  d-flex justify-content-end">
          <Button
            className="main-button w-25 fw-semibold rounded-pill py-2 "
            variant=""
            onClick={() => {
              setConfirmed(true);
              handleClose();
            }}
          >
            Update Table
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateTable;
