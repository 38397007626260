import { React, useEffect, useState } from "react";
import OutputList from "../components/output/OutputList";
import Result from "../components/TableResults/Result";
import OutputHeader from "../components/output/OutputHeader";
import { Form } from "react-bootstrap";
import ReportResult from "../components/table/ReportResult";
import EditReport from "./EditReport";
import GetBaseUrl from "../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const SavedOutput = () => {
  const [tablesList, setTablesList] = useState([]);
  const [viewTable, setViewTable] = useState();
  const [reload, setReload] = useState(null);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [EditReportName, setEditReportName] = useState(null);
  const [EditReportId, setEditReportId] = useState(null);
  const [EditReportIdHead, setEditReportIdHead] = useState([]);

  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );
  //---------------------------------------------------------------------
  useEffect(() => {
    axios
      .get(BaseUrl + "/Report/GetSavedReports", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setTablesList(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, [reload]);
  //---------------------------------------------------------------------
  function syncReport(Id) {
    setReload(false);
    axios
      .get(BaseUrl + "/Report/SyncReportById/" + Id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Report Syncing has been Done", {
            position: toast.POSITION.TOP_RIGHT,
          });

          setReload(true);
        }
      })
      .catch(() => {
        toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {});
  }
  //---------------------------------------------------------------------

  return (
    <div>
      {selectedRelation ? (
        <EditReport
          selectedRelation={selectedRelation}
          setSelectedRelation={setSelectedRelation}
          EditReportName={EditReportName}
          EditReportId={EditReportId}
          setEditReportName={setEditReportName}
          setEditReportId={setEditReportId}
          setEditReportIdHead={setEditReportIdHead}
          EditReportIdHead={EditReportIdHead}
          setViewTable={setViewTable}
          setReload={setReload}
        />
      ) : (
        <div className="rounded-5 main-bg p-35 min-vh-85">
          <div className="row">
            <div className="col-lg-4 col-xl-3 px-0 pe-3">
              <div className="top-bg p-4 rounded-5 min-vh-85">
                <OutputList
                  renderItem={renderItem}
                  tablesList={tablesList}
                  setViewTable={setViewTable}
                  setReload={setReload}
                  setSelectedRelation={setSelectedRelation}
                  syncReport={syncReport}
                  setEditReportName={setEditReportName}
                  setEditReportId={setEditReportId}
                  EditReportIdHead={EditReportIdHead}
                />
              </div>
            </div>
            <div className="col-lg-8 col-xl-9 px-0 ">
              <div className="top-bg rounded-5 min-vh-85">
                {viewTable ? (
                  <ReportResult
                    TableId={viewTable}
                    setViewTable={setViewTable}
                    HeaderType="ViewReportTable"
                    tableRowsDefault="10"
                    onlyView
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavedOutput;
