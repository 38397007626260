import { React, useEffect, useState } from "react";
import CreateDirectory from "../components/Directory/CreateDirectory";
import DirectoryTable from "../components/Directory/DirectoryTable";
import ConnectedDirectory from "../components/settings/ConnectedDirectory";
import Office360 from "../pages/Platforms/Office360";
import Canvas from "../pages/Platforms/Canvas";
import AUD from "../pages/Platforms/AUD";
import BlackBoard from "../pages/Platforms/BlackBoard";

const ChooseDirectory = ({}) => {
  const [viewConnection, setViewConnection] = useState(null);
  const [PlatformTableId, setPlatformTableId] = useState("0");
  useEffect(() => {}, [viewConnection, PlatformTableId]);

  return (
    <>
      {!viewConnection ? (
        <div className="rounded-5 main-bg min-vh-85">
          <div className="top-bg rounded-5 min-vh-85">
            <CreateDirectory />
            <DirectoryTable
              PlatformTableId="0"
              setViewConnection={setViewConnection}
              setPlatformTableId={setPlatformTableId}
            />
          </div>
        </div>
      ) : viewConnection && PlatformTableId === 1 ? (
        <Office360
          ConnectionId={viewConnection}
          setViewConnection={setViewConnection}
        />
      ) : viewConnection && PlatformTableId === 2 ? (
        <Canvas
          ConnectionId={viewConnection}
          setViewConnection={setViewConnection}
        />
      ) : viewConnection && PlatformTableId === 3 ? (
        <BlackBoard
          ConnectionId={viewConnection}
          setViewConnection={setViewConnection}
        />
      ) : viewConnection && PlatformTableId === 4 ? (
        <AUD
          ConnectionId={viewConnection}
          setViewConnection={setViewConnection}
        />
      ) : null}
    </>
  );
};

export default ChooseDirectory;
