import React from 'react'
import GenerateDashboard from '../modals/GenerateDashboard'
import { Button, Dropdown } from 'react-bootstrap'
import xls from '../../assets/svg/xls_1.svg'
import pdf from '../../assets/svg/pdf.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom';


const OutputHeader = (props) => {
  return (
    <div className='d-flex flex-column mb-3'>
    <div className='mb-2'>
    <div className='d-flex justify-content-between align-items-center'>
    <h5 className='fw-semibold mb-0'>{props.TableName} &nbsp;
       <span className = "fw-normal mb-0 fs-7 mt-2" style={{'float':'right'}}> {props.IsScheduled ? '{Scheduled}'  : ''}   </span> 

    </h5>
      {/* <span className='fs-7 mx-1'>{props.LastUpdate}</span> */}
    </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center mt-2'>
            <i className='edu ic-sched'/>
            <span className='fw-semibold mx-2 fs-8'>Last Updated: </span>
            <span className='fs-8'>{props.LastUpdate}</span>

          </div>
        </div>
    </div>
   <div>
   <div className='d-flex justify-content-end'> 
           











   <Dropdown>
      <Dropdown.Toggle variant='' id="dropdown-basic" className='rounded-pill main-button px-3 fw-medium mx-2 fs-8' onClick={()=> props.ExportReport(props.TableId)}>
        Export
      </Dropdown.Toggle>
    </Dropdown>


   </div>
   </div>
   </div>
  )
}

export default OutputHeader
