import { React } from "react";
import RelationshipCard from "./RelationshipCard";
import { Rings } from "react-loader-spinner";

const RelationshipLayout = ({
  removeFromRelationship,
  setMainJsonBody,
  Work_Tables,
  MainJsonBody,
  AddNew,
  loadingText,
  loading,
  setNewName,
}) => {
  return (
    <div className="rel_bg min-vh-92 p-4">
      <div className="row">
        {loading ? (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <Rings color="#05BC86" height={150} width={150} /> {loadingText}...
          </div>
        ) : (
          <>
            {Work_Tables.length === 0 ? (
              <h3 className="text-center">No Tables To View Relationships</h3>
            ) : (
              Work_Tables.map((item, index) => (
                <RelationshipCard
                  key={index}
                  TableId={item.TableId}
                  tableName={item.TableTitle}
                  tableHead={item.TableHeader}
                  setMainJsonBody={setMainJsonBody}
                  MainJsonBody={MainJsonBody}
                  Sequence={item.Sequence}
                  SelectedColumn={item.SelectedColumn}
                  removeFromRelationship={removeFromRelationship}
                  AddNew={AddNew}
                  setNewName={setNewName}
                />
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RelationshipLayout;
