import React from 'react'
import ReportTitle from '../components/Reports/ReportTitle'
import TableResult from '../components/table/TableResult'
import Result from '../components/TableResults/Result'

function ViewReport() {
  return (
    <div>
       <div className = "rounded-5 main-bg min-vh-85">
      <div className = "top-bg rounded-5 min-vh-85">
        <ReportTitle/>
        <div>
            <Result/>
        </div>
        </div>
      </div>
    </div>
  )
}

export default ViewReport
