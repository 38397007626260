import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  EdgeProps,
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
  Handle,
  Position,
  useStore,
} from "reactflow";
import OneLink from "../../components/relationship/one";
import ManyLink from "../../components/relationship/Many";
import "./style.css";
import rel_details from "../../assets/svg/details.svg";
import { width } from "@mui/system";

// this is a little helper component to render the actual edge label
function EdgeLabel({ transform, label, display }) {
  return (
    <div
      style={{
        position: "absolute",
        background: "transparent",
        padding: 10,
        color: "#ff5050",
        fontSize: 10,
        fontWeight: 700,
        transform,
        display: display,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

const CustomEdge = ({
  id,

  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [EdgeLabelDisplay, setEdgeLabelDisplay] = useState("none");

  const onEdgeClick = (evt, id, data) => {
    evt.stopPropagation();

    data.setRandom2(id);
  };

  //----------------------------------------------------

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        {data.startLabel && (
          <EdgeLabel
            transform={`translate(-20%, -10%) translate(${sourceX}px,${sourceY}px)`}
            label={data.Link1}
            display={EdgeLabelDisplay}
          />
        )}

        {data.startLabel && (
          <EdgeLabelRenderer>
            <div
              style={{
                position: "absolute",
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                fontSize: 12,
                // everything inside EdgeLabelRenderer has no pointer events by default
                // if you have an interactive element, set pointer-events: all
                pointerEvents: "all",
              }}
              className="nodrag nopan hoverEdge00"
            >
              <button
                className="edgebutton rounded-circle d-flex align-items-center justify-content-center"
                onClick={(event) => onEdgeClick(event, id, data)}
                onMouseOver={(e) => {
                  e.preventDefault();
                  setEdgeLabelDisplay("block");
                  data.setRandom2("Focus_" + id);
                }}
                onMouseLeave={(e) => {
                  e.preventDefault();
                  setEdgeLabelDisplay("none");
                  data.setRandom2(Math.random);
                }}
              >
                <img src={rel_details} style={{ width: "45%" }} />
              </button>
            </div>
          </EdgeLabelRenderer>
        )}

        {data.endLabel && (
          <EdgeLabel
            transform={`translate(-80%, -100%) translate(${targetX}px,${targetY}px)`}
            label={data.Link2}
            display={EdgeLabelDisplay}
          />
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
