import React from 'react'
import GenerateDashboard from '../modals/GenerateDashboard'
import { Button, Dropdown } from 'react-bootstrap'
import CustomHeader from '../modals/CustomHeader'

function FileHeader() {
  return (
    <div className='d-flex flex-column mb-3'>
    <div className='mb-2'>
    <h5 className='fw-semibold mb-0'>Student Infromation</h5>
    </div>
   <div>
   <div className='d-flex justify-content-end'>
   <CustomHeader/>
   <Button variant='' className='main-button rounded-pill fw-semibold'>Save as Table</Button>
   </div>
   </div>
   </div>
  )
}

export default FileHeader
