import React, { useState } from 'react'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import office from '../../assets/svg/office.svg'
import office_icon from '../../assets/svg/office-icon.svg'
import DirectoryFiles from './DirectoryFiles';
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";




function Office360(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState(""); 
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Redirect = () => {
      window.location.href='/Office360/Platform/Tables';
    };

    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");




    const [Office_ClientID, setOffice_ClientID] = useState('');
    const [Office_TenantID, setOffice_TenantID] = useState('');
    const [Office_ClientSecret, setOffice_ClientSecret] = useState('');

    const handleChange = event => {
          if(event.target.name==='Office_ClientID'){
            setOffice_ClientID(event.target.value);
          }
          else if(event.target.name==='Office_TenantID'){
            setOffice_TenantID(event.target.value);
          }
          else if(event.target.name==='Office_ClientSecret'){
            setOffice_ClientSecret(event.target.value);
          }

    };   
    const handleConnection = async () => {

      if(!Office_TenantID || !Office_ClientID || !Office_ClientSecret ){  toast.warning('Fill Required Fields') ; return false; }
      const ToPost = {"platformId": 1, "client_id": Office_ClientID, "tenantId": Office_TenantID, "client_secret": Office_ClientSecret}; 
      const response = await axios
      .patch(BaseUrl+"/Platform/Office365Connection/"+props.ConnectionId, ToPost, {   
        headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error));
          if (response.data===true && response.status===200 ) {

                toast.success('Updated successfully', {
                  position: toast.POSITION.TOP_RIGHT
                });
                handleClose();
                props.setReloadTable(Math.random())
                //window.location.href='/DataInput/Directory'
          }
          else{
                toast.error('Incorrect credentials', {
                  position: toast.POSITION.TOP_RIGHT
                });
          }
};




  return (

<>
{props.OnlyButton ?    
          <div>
              <Button className='rounded-pill fw-semibold fs-7 mx-1' variant="primary" onClick={handleShow}>
              Update Connection
              </Button>
              <Modal centered show={show} onHide={handleClose}>
                <Modal.Header className='p-3 pb-0' closeButton>
                </Modal.Header>
                <Modal.Body className='px-4'>

                    <div className='d-flex flex-column align-items-center w-100'>
                        <img src = {office} className=' w-100 mb-4'/>
                        <div className='w-100'>
                        <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Tenant ID" name='Office_TenantID' onChange={handleChange} />
                        <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client ID" name='Office_ClientID' onChange={handleChange} />
                        <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client Secret" name='Office_ClientSecret' onChange={handleChange}  />

                        <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
                            Link Account
                        </Button>
                        </div>
                    </div>

                </Modal.Body>
              </Modal>
         </div>

:  
<div>
<Button className='p-0' variant="" onClick= {props.IsConnected ? Redirect : handleShow }>
          <div className='d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3'>
            <div className='rounded-2 d-flex justify-content-center bg-body-secondary w-20 p-3 mb-2'>
              <img src={office_icon}/>
            </div>
            <span className='fs-5 fw-semibold mb-1'>Office365</span>
            <span className='fs-7 text-start'>A cloud-powered productivity platform by Microsoft</span>
          </div>
</Button>
<Modal centered show={show} onHide={handleClose}>
<Modal.Header className='p-3 pb-0' closeButton>
</Modal.Header>
<Modal.Body className='px-4'>

    <div className='d-flex flex-column align-items-center w-100'>
        <img src = {office} className='w-50 mb-4'/>
        <div className='w-100'>
        <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Tenant ID" />
        <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client ID" />
        <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client Secret" />

        <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
                            Link Account
        </Button>
        </div>
    </div>

</Modal.Body>
</Modal>


</div>
}
</>



  )
}

export default Office360
