import { Fragment, React, useEffect, useState, useContext } from "react";
import ExportCard from "../components/Jobs/ExportCard";
import SyncCard from "../components/Jobs/SyncCard";
import note from "../assets/svg/note.svg";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GetBaseUrl from "../functions/SetBaseUrl";
import { TitleContext } from "../components/layout/Layout";

function Jobs(props) {
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const BaseUrl = GetBaseUrl()[0];
  const [Cards, setCards] = useState([]);
  const [NewCards, setNewCards] = useState([]);
  const [Status, setStatus] = useState("");
  const [Type, setType] = useState("");
  const [selected, setSelected] = useState("");
  //----------------------------------------------
  useEffect(() => {
    axios
      .get(BaseUrl + "/Job/GetJobs/1/120000", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setCards(res.data.jobs);
        setNewCards(res.data.jobs);
      })
      .catch(() => {
        toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {});
  }, []);
  //----------------------------------------------
  function ViewType(type, Status) {
    setType(type);
    let array = [...Cards];
    let arr = [];
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (Status && type) {
        if (element.jobType === type && element.jobStatus === Status) {
          arr.push(element);
        }
      } else if (!Status && type) {
        if (element.jobType === type) {
          arr.push(element);
        }
      } else if (Status && !type) {
        if (element.jobStatus === Status) {
          arr.push(element);
        }
      } else if (!Status && !type) {
        arr.push(element);
      }
    }
    setNewCards(arr);
  }
  //----------------------------------------------
  const handleChange = (e) => {
    setStatus(e.target.value);
    ViewType(Type, e.target.value);
  };

  return (
    <div className="rounded-5 main-bg min-vh-85">
      <div className="top-bg rounded-5 min-vh-85">
        <div className="d-flex justify-content-between align-items-center mb-0 p-3 ">
          <div className="">
            <h5 className="fw-semibold mb-2 fs-22">Jobs</h5>

            <div className="d-flex">
              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("", Status)}
              >
                All
              </Button>
              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("Upload File", Status)}
              >
                Upload File
              </Button>
              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("Edit Table", Status)}
              >
                Edit Table
              </Button>
              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("Save Table", Status)}
              >
                Save Table
              </Button>
              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("Sync Table", Status)}
              >
                Sync Table
              </Button>
              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("Platform Export", Status)}
              >
                Platform Export
              </Button>

              <Button
                className={
                  "border-button rounded-pill me-2 fw-semibold " + selected
                }
                variant=""
                onClick={() => ViewType("Find and Replace", Status)}
              >
                Find and Replace
              </Button>
              <Button
                className={"sec-border rounded-pill fw-semibold " + selected}
                variant=""
                onClick={() => ViewType("Sync Report", Status)}
              >
                Sync Report
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-center pt-4 pr-2">
            <Form.Check
              label={"In Progress"}
              name={"status"}
              className="me-2"
              onChange={handleChange}
              type="radio"
              value={"In Progress"}
            />
            <Form.Check
              label={"Completed"}
              name={"status"}
              className="me-2"
              onChange={handleChange}
              type="radio"
              value={"Completed"}
            />
            <Form.Check
              label={"All"}
              name={"status"}
              className="me-2"
              onChange={handleChange}
              type="radio"
              defaultChecked={"checked"}
              value={""}
            />
          </div>
          <div className="d-flex1 align-items-center hide">
            <img src={note} />
            <span className="ms-1 fw-medium fs-7">
              Your Files are available for 30 days
            </span>
          </div>
        </div>

        <div className="row">
          {NewCards &&
            NewCards.map((item, index) => (
              <div key={index} className={"col-3 mb-3 jobCard " + item.jobType}>
                {item.jobType !== "Sync Report" ? (
                  <ExportCard
                    jobId={item.jobId}
                    reportId={item.reportId}
                    reportName={item.reportName}
                    jobType={item.jobType}
                    jobStatus={item.jobStatus}
                    ReportData={item.ReportData}
                    createdOn={item.createdOn}
                  />
                ) : (
                  <SyncCard
                    jobId={item.jobId}
                    reportId={item.reportId}
                    reportName={item.reportName}
                    reportFullUrl={item.reportFullUrl}
                    jobType={item.jobType}
                    jobStatus={item.jobStatus}
                    createdOn={item.createdOn}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Jobs;
