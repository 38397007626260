import React from 'react'
import blue_report from '../../assets/images/report-blue.png'
import empty_user from '../../assets/svg/empty-user.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const ReportCard = (props) => {
  return (
    <div className="report-card pointer" onClick={() => props.setViewTable(props.Id)}>
    <img className='w-100' alt='Img' src = {blue_report}/>
    <div className='d-flex justify-content-between align-items-end'>
        <div className=''>
            <h4 className='fs-6 fw-medium mt-2 mb-0'>{props.Title}</h4>
            <span className='fs-9 fw-normal light-dark'>Last updated: {props.LastEdit}</span>
        </div>
        <img alt='Img' src ={empty_user}/>

    </div>
    </div>      
  )
}

export default ReportCard
