import {React, useEffect, useState, useRef } from "react";
import PublicTable from '../../components/API_Table/main'
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";

const TableResult = ({ ConnectionId, PlatformTableId, tableRowsDefault, setViewDataFieldsTable, setViewTable, HeaderType, DownloadExcel,tableRef,PlatformTable}) => {




  const BaseUrl = GetBaseUrl()[0]   ;
  const [tableHead, setTableHead] = useState([]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState('')
  const [tableName, setTableName] = useState('')
  var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");


     
useEffect(() => {


  let tableHead = [];
    let tablebodyCol = [];

    setTAbleUrl(BaseUrl  +  '/Platform/GetAPIData/'+ConnectionId+'/'+ PlatformTableId)

    axios
    .get( BaseUrl  +  '/Platform/GetAPIData/'+ConnectionId+'/'+ PlatformTableId, {
        headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        },
    })
    .then((res) => { 
      if(res.data){ 
          setTableName(res.data.tableName)
          res.data.headers.map((item, index) => tableHead.push( item )     )
          res.data.headers.map((item, index) => tablebodyCol.push( item )               )        
      }

    })
    .catch((res) =>{
      toast.error(res+'', {
        position: toast.POSITION.TOP_RIGHT
      });
    })
    // .catch((error) => {
    //     // Error
    //     if (error.response) {
    //         // The request was made and the server responded with a status code
    //         // that falls out of the range of 2xx
    //         //console.log(error.response.data);
    //         //console.log(error.response.status);
    //         //console.log(error.response.headers);
    //     } else if (error.request) {
    //         // The request was made but no response was received
    //         // `error.request` is an instance of XMLHttpRequest in the 
    //         // browser and an instance of
    //         // http.ClientRequest in node.js
    //         //console.log('Error111'+error.request);
    //     } else {
    //         // Something happened in setting up the request that triggered an Error
    //         //console.log('Error', 'Error333'+error.message);
    //     }
    // })

    .finally(() =>{

      setTableHead(tableHead) 
      setBodyCol(tablebodyCol) 
    });

  
}, [PlatformTableId])










return (
<>  
{tableUrl
  ?
    <PublicTable
    loadingText='Loading Table'
    TableTitle={tableName}
    TableId= {PlatformTableId}  
    TableUrl= {tableUrl}  
    TableHead= {tableHead}      
    BodyCol=  {bodyCol}
    lastColumn={false}
    withOutBorder={false}
    defaultRows={tableRowsDefault ? tableRowsDefault : '10'}
    withHeader ={HeaderType}
    PlatformTable={PlatformTable}

    setViewDataFieldsTable={setViewDataFieldsTable}
    setViewTable={setViewTable}
    DownloadExcel={DownloadExcel}
    tableRef={tableRef}
    BodyJsonItem='rows'
    ConnectionId={ConnectionId}
    />
  :
null
}

  
  </>

  )
}

export default TableResult
