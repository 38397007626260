import React from 'react'
import FileHeader from '../components/DirectoryPlatform/FileHeader'
import FilesBar from '../components/DirectoryPlatform/FilesBar'
import TableResult from '../components/table/TableResult'

const Platfrom = () => {
  return (
    <div>
    <div className = "rounded-5 main-bg min-vh-85">
   <div className='row'>
   <div className = "col-lg-4 col-xl-3 px-0 pe-3">
    <div className = "top-bg p-3 rounded-5 min-vh-85">
   <FilesBar/>
    </div>
    </div>
    <div className='col-lg-8 col-xl-9'>
    <div className = "top-bg p-4 rounded-5 min-vh-100">
        <FileHeader/>
        <TableResult/>
      </div>
    </div>
   </div>
    </div>
    </div>
  )
}

export default Platfrom
