import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  radioType = "",
  affected,
  disabled,
}) {
  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;
    // console.log(e.target.value);
    let isChecked = e.target.checked;
    let inputType = e.target.type;

    res = name;
    if (isChecked && inputType === "checkbox") {
      val = true;
    } else if (!isChecked && inputType === "checkbox") {
      val = false;
    }
    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  return (
    <div className={disabled + " mb-2"}>
      <div className="d-flex align-items-center py-2">
        <Form.Check
          name={Id}
          className="me-2"
          onChange={handleChange}
          type="checkbox"
          defaultChecked={value === "true" ? true : false}
          value={value ? "true" : "false"}
        />
        <span className="fw-medium">{name}</span>
      </div>
    </div>
  );
}
export default Component;
