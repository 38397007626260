import {React, useEffect, useState } from "react";
import ReportHeader from '../components/report-cards/ReportHeader'
import ReportCard from '../components/report-cards/ReportCard'
import { Link } from 'react-router-dom'
import GetBaseUrl from '../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import ReportResult from '../components/table/ReportResult'
import OutputList from '../components/output/OutputList'


import * as ReactDOM from "react-dom";
import { DiagramComponent } from "@syncfusion/ej2-react-diagrams";
import { HierarchicalTree, Inject, DataBinding } from "@syncfusion/ej2-react-diagrams";
import { DataManager } from "@syncfusion/ej2-data";



const BaseUrl = GetBaseUrl()[0]   ;
const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");



const Home = (props) => {












    const [HomePage, setHomePage] = useState(GetBaseUrl()[1]+"_DefaultHomePage");
    const [viewReport, setViewReport] = useState();
    const [tablesList, setTablesList] = useState([]);
    const renderItem = (item, index) =>    <div className='fw-semibold' key={index}>{item}</div>   


    


    useEffect(() => { 
    
        axios
        .get( BaseUrl  +   '/Report/GetSavedReports', {
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
        })
        .then((res) => {
          setTablesList(res.data )
        }).finally(() => {}) ;
    
        
    
    }, [])

  return (
    <div className = "rounded-5 main-bg min-vh-85">









{viewReport  ?

            <div className='row'>
                            <div className = "col-lg-4 col-xl-3 px-0 pe-3">
                                    <div className = "top-bg p-4 rounded-5 min-vh-85">
                                    <OutputList 
                                                    renderItem={renderItem}
                                                    tablesList={tablesList}
                                                    setViewTable={setViewReport}
                                                    />
                                    </div>
                            </div>

                            <div className = "col-lg-8 col-xl-9 px-0 ">
                                <div className = "top-bg rounded-5 min-vh-85">
                                    <ReportResult
                                    TableId={viewReport}
                                    setViewTable={setViewReport}
                                    HeaderType='ViewReportTable'
                                    tableRowsDefault='10'
                                    />
                                </div>
                            </div>
            </div>


:





      <div className = "top-bg rounded-5 min-vh-85">
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className=''>
                    <h5 className='fw-semibold mb-0'>Recent Reports</h5>
                    <span className='fs-7'>{tablesList.length} results</span>
                </div>
                <Link to='/Reports/Edit-Report'   >
                    <button className='btn rounded-pill main-button px-3 fw-medium fs-8'>Create New Report</button>
                </Link>
            </div>

            <div className='row'>
            {tablesList && tablesList.map((item, index) =>

                <div className='col-3'>
                <ReportCard
                key={index}
                setViewTable={setViewReport}
                Id= {item.relId}
                Title={item.reportName}
                LastEdit={item.updatedOn}
                
                />
                </div>
            )}
           </div>
      </div>
}


    </div>
  )
}

export default Home



