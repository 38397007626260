import {React, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';
import drag_ic from '../../assets/svg/drag.svg'
import arrange from '../../assets/svg/arrange.svg'



function HeaderOrder(props) {



useEffect(() => {  



props.setDragDropList(props.TableHeadOrder)



}, [props.TableHeadOrder])





  const onDragComplete = (result) => {
    if (!result.destination) return;
 
    const arr = [...props.dragDropList];
 
    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);
 
    //Updating the list
    props.setDragDropList(arr);


    // console.log(`dragDropList`);
    // console.log(arr);
  };
    
  return (
    <div className="">
    <Dropdown>
      <Dropdown.Toggle className='fw-semibold d-flex align-items-center' variant="" id="dropdown-basic">
        <img src = {arrange} className='me-1'/>
        Header Order
      </Dropdown.Toggle>

      <Dropdown.Menu className='px-2 pt-3 head-order'>
      <DragDropContext onDragEnd={onDragComplete}>
  <Droppable droppableId="drag-drop-list">
    {(provided, snapshot) => (
      <div
        className="drag-drop-list-container"
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        {props.dragDropList.map((item, index) => (
          <Draggable key={item.id} draggableId={item.label} index={index}>
            {(provided) => (
              <div
                className="item-card"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div className='d-flex align-items-center mb-3'>
                <img src = {drag_ic}/>
                <p className="ms-2 mb-0 fw-medium fs-7 label">{item.label}</p>
                </div>
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
</DragDropContext>
      </Dropdown.Menu>
    </Dropdown>
       
      </div>
  )
}

export default HeaderOrder
