import { React, useEffect, useState, useRef } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { useLottie } from "lottie-react";
import loading_lottie from "../../assets/lottie/loading.json";

import axios from "axios";

const TableResult = (props) => {
  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [tableOriginalHead, setTableOriginalHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);

  const [tableHeadPass, setTableHeadPass] = useState([]);
  const [tableOriginalHeadPass, setTableOriginalHeadPass] = useState([]);
  const [bodyColPass, setBodyColPass] = useState([]);

  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  const [LastUpdate, setLastUpdate] = useState("");
  const [ReloadTable, setReloadTable] = useState("");
  const [aTH, setATH] = useState([]);
  const [NewValue, setNewValue] = useState(null);

  const [method, setMethod] = useState("post");

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const options = {
    animationData: loading_lottie,
    loop: true,
  };

  const { View } = useLottie(options);
  useEffect(() => {
    let order = [];
    let tableHead = [];
    let tableOriginalHead = [];
    let tablebodyCol = [];
    let tableHeadIds = [];

    if (props.viewReport) {
      setTAbleUrl(BaseUrl + "/Report/GetReportByRelationId");

      if (props.EditReportId) {
        setMethod("get");
        setTAbleUrl(BaseUrl + "/Report/GetReportById/" + props.EditReportId);

        axios
          .get(BaseUrl + "/Report/GetReportById/" + props.EditReportId, {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            // console.log(res.data);
            setATH(res.data.columns);

            res.data.columns.map((item) => {
              tableHeadIds.push(item["columnId"]);
              tableHead.push({
                tableId: item["tableId"],
                columnName: item["columnName"],
              });

              tablebodyCol.push({
                tableId: item["tableId"],
                columnName: item["originalName"],
              });

              tableOriginalHead.push({
                tableName: item["tableName"],
                columnName: item["originalName"],
              });

              order.push({
                id: item["columnId"],
                label: item["columnName"],
              });
            });
          })
          .catch(() => {})
          .finally(() => {
            props.setTableHeadOrder(order);
            setTableHead(tableHead);
            setTableOriginalHead(tableOriginalHead);
            setBodyCol(tablebodyCol);

            setTableHeadPass(tableHead);
            setTableOriginalHeadPass(tableOriginalHead);
            setBodyColPass(tablebodyCol);

            props.setEditReportIdHead(tableHeadIds);
            setReloadTable(Math.random(400));
          });
      } else {
        setMethod("post");
        axios
          .post(BaseUrl + "/Report/GetReportByRelationId", props.obj, {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            setATH(res.data.columns);
            res.data.columns.map((item) => {
              tableHeadIds.push(item["columnId"]);
              tableHead.push({
                tableId: item["tableId"],
                columnName: item["columnName"],
              });

              tablebodyCol.push({
                tableId: item["tableId"],
                columnName: item["columnName"],
              });

              tableOriginalHead.push({
                tableName: item["tableName"],
                columnName: item["columnName"],
              });

              order.push({ id: item["columnId"], label: item["columnName"] });
            });
          })
          .catch(() => {})
          .finally(() => {
            props.setTableHeadOrder(order);

            setTableHead(tableHead);
            setTableOriginalHead(tableOriginalHead);
            setBodyCol(tablebodyCol);

            setTableHeadPass(tableHead);
            setTableOriginalHeadPass(tableOriginalHead);
            setBodyColPass(tablebodyCol);

            setReloadTable(Math.random(400));
          });
      }
    }
  }, [props.viewReport]);

  //-----------------------------------------
  useEffect(() => {
    props.setTitle(props.newName);
  }, [props.newName]);

  useEffect(() => {
    props.setAllTableHead(aTH);
  }, [aTH]);

  //------------------------
  const ChangeHeader = (event) => {
    let arr = [...aTH];
    // console.log(arr);
    let defaultValue = event.target.defaultValue;
    let Id = event.target.id;

    document.getElementById(defaultValue + "_" + Id).innerHTML =
      event.target.value;
    arr[event.target.id].columnName = event.target.value;

    props.setAllTableHead(arr);
  };
  //------------------------

  useEffect(() => {
    let newTableHead = [...tableHead];
    let newTableOriginalHead = [...tableOriginalHead];
    let newBodyCol = [...bodyCol];

    for (let i = 0; i < props.obj.Tables.length; i++) {
      const element = props.obj.Tables[i].columns;
      const tableName = props.obj.Tables[i].tableName;
      const tableId = props.obj.Tables[i].tableId;
      for (let j = 0; j < element.length; j++) {
        const EachColumn = element[j];
        let idx1 = newTableHead.findIndex(
          (item) =>
            item.tableId === tableId &&
            item.columnName === EachColumn.columnName
        );
        let idx2 = newTableOriginalHead.findIndex(
          (item) =>
            item.tableName === tableName &&
            item.columnName === EachColumn.columnName
        );
        let idx3 = newBodyCol.findIndex(
          (item) =>
            item.tableId === tableId &&
            item.columnName === EachColumn.columnName
        );
        if (!EachColumn.isVisible) {
          // console.log(EachColumn.columnName);

          if (idx1 > -1) {
            newTableHead.splice(idx1, 1);
          }
          if (idx2 > -1) {
            newTableOriginalHead.splice(idx2, 1);
          }
          if (idx3 > -1) {
            newBodyCol.splice(idx3, 1);
          }
        }
      }
    }

    setTableHeadPass(newTableHead);
    setTableOriginalHeadPass(newTableOriginalHead);
    setBodyColPass(newBodyCol);
  }, [props.obj, ReloadTable]);

  return (
    <>
      {tableUrl ? (
        <PublicTable
          loading={View}
          TableTitle={props.newName}
          TableId="Report"
          TableId1={props.obj}
          TableUrl={tableUrl}
          TableMethod={method}
          TableHead={tableHeadPass}
          BodyCol={bodyColPass}
          lastColumn={false}
          withOutBorder={false}
          defaultRows="10"
          withHeader="Nothing"
          firstColumn={false}
          Internal={true}
          ReportTable
          ReloadTable={ReloadTable}
          NewValue={NewValue}
          EditReportId={props.EditReportId}
          ReportColumn
          TableOriginalHead={tableOriginalHeadPass}
          EditableHeader={props.EditableHeader}
          EditableHeaderPosition={props.EditableHeaderPosition}
          allTableHead={props.allTableHead}
          ChangeHeader={ChangeHeader}
        />
      ) : null}
    </>
  );
};

export default TableResult;
