import React from 'react'
import QueryArea from '../components/query/QueryArea'

import Result from '../components/TableResults/Result'

const AddQuery = () => {
  return (
    <div className = "rounded-5 main-bg min-vh-85">
    <div className = "top-bg rounded-5 h-50 mb-4">
      <QueryArea/>
    </div>
    <div className = "top-bg p-4 rounded-5 h-50">
    <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className=''>
            <h4 className = "fw-semibold mb-0 fs-5">Result</h4>
        </div>
         </div>
        <Result/>
      </div>
    </div>
  )
}

export default AddQuery
