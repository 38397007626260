import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SidebarReport = (props) => {
  const [open, setOpen] = useState(false);
  const active = props.active ? "active" : "";

  return (
    <div>
      <div onClick={() => setOpen(!open)}>
        <div className="sidebar__item" onClick={() => props.RouteClick()}>
          <Link to="/Reports/Saved-Reports">
            <div className={`sidebar__item-inner ${active}`}>
              <i className="edu ic-output"></i>
              <span>Reports</span>
            </div>
          </Link>
        </div>
      </div>
      {/* <Collapse in={open} className='collapse-content'>
            <ul>
                <li>
                    <Link to='/Reports/Edit-Report'>
                    <div className="sidebar__item">
                    <div className={`sidebar__item-inner ${active}`}>
                        <i className='edu ic-menu-arrow'></i>
                        <span>Create Report</span>
                    </div>
                    </div>
                    </Link>
                </li>
                
            </ul>
            </Collapse> */}
    </div>
  );
};

export default SidebarReport;
