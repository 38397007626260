import React, { useState, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import GetBaseUrl from '../../functions/SetBaseUrl'
import axios from "axios";




function AssignToProject(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
  
    const [selectedOption, setSelectedOption] = useState(null);
    const [dataOptions, setDataOptions] = useState(null);
  

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");


    const [project, setProject] = useState('');
    const handleChange = event => {

        setSelectedOption(event);

        setProject(event.value);
    };



    useEffect(() => {

        let tempOptions=[];
        const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");
        axios
        .get( BaseUrl  +  '/Relations/GetProjects', {
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
        })
        .then((res) => {
          if(res.data && res.data !=='No Project found.'){

                res.data.map((item) => (
                  tempOptions.push(
                    {
                      value: item.projectId,
                      text: item.projectName,
                    }             
                  )
                ))   
          }          

        }).finally(() =>{
          setDataOptions(tempOptions) 
        });
    
    }, [])





const handleAssign = async (R_Id) => {
      const response = await axios
          .post(BaseUrl  +  '/Relations/AddUpdateProjectToRelation/'+ project +'/'+ R_Id, {
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error))
                if (response.status===200) {

                    toast.success('Relationship has been assigned', {
                    position: toast.POSITION.TOP_RIGHT
                    });
                    handleClose();
                    props.setNewName('')        
    
                }      
};




  return (
    <div>
       <Button className="p-0 fs-7" variant="" onClick={handleShow}>
       <i className='edu ic-add-table'> </i>Assign to Project
      </Button>

        <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold'>Assign to Project</Modal.Title>
        </div>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7">Project Name</Form.Label>

              <Select
                placeholder="Select A Project"
                value={selectedOption}
                options={dataOptions}
                onChange={handleChange}
                getOptionLabel={e => (
                <div className='fs-7 py-2'  style={{ display: 'flex', alignItems: 'center' }}>
                    {e.icon}
                    <span className='px-1 py-0'  style={{ marginLeft: 5 }}>{e.text}</span>
                </div>
                )}
            />


              </Form.Group>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={() => handleAssign(props.R_Id)}>
            Add to Project
          </Button>
        </Modal.Footer>
        </Modal>
    
    </div>
  )
}

export default AssignToProject
