import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import logo from "../../assets/svg/logo.svg";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import sidebar_child from "../../assets/JsonData/sidebar_child.json";
import { Button } from "antd";
import SidebarChild from "./SidebarChild";
import { Collapse } from "react-bootstrap";
import jobs from "../../assets/svg/jobs.svg";

import SidebarReport from "./SidebarChild2.jsx";
import GetBaseUrl from "../../functions/SetBaseUrl";
import styles from "../../components/layout/Layout.module.scss";
import sidebarBackIcon from "../../assets/images/sidebarBackIcon.svg";

const SidebarItem = (props) => {
  // {
  //     "display_name": "Add Query",
  //     "route": "/Query",
  //     "icon": "edu ic-set-query"
  // },
  // {
  //     "display_name": "Saved Query",
  //     "route": "/Query/Saved-Query",
  //     "icon": "edu ic-menu-arrow"
  // },
  // {
  //     "display_name": "Reports",
  //     "route": "/Reports",
  //     "icon": "edu ic-output"
  // },

  useEffect(() => {}, []);

  const active = props.active ? "active" : "";
  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const Sidebar = (props) => {
  const activeItem = sidebar_child.findIndex(
    (item) => item.route === props.location.pathname
  );
  const [open, setOpen] = useState(false);
  const active = props.active ? "active" : "";
  //--------------------------------------------
  function RouteClick() {
    window.innerWidth > 1200
      ? props.setCollapsedNavbar(false)
      : props.setCollapsedNavbar(true);
  }

  return (
    <div
      className={`sidebar  ${styles["responsive-sidebar"]}  ${
        props.collapsedNavbar
          ? styles["responsive-sidebar-collapsed"]
          : styles[""]
      }`}
    >
      <img
        src={sidebarBackIcon}
        className={`${styles["backIcon"]}`}
        alt="sidebarBackIcon"
        onClick={() => RouteClick()}
      />
      <Link to="/">
        <div
          className="sidebar__logo"
          onClick={() => {
            localStorage.setItem(
              GetBaseUrl()[1] + "_DefaultHomePage",
              Math.random(450)
            );
          }}
        >
          <img src={logo} alt="company logo" />
        </div>
      </Link>

      <Link to="/home">
        <div
          className="sidebar__item"
          onClick={() => {
            RouteClick();
            localStorage.setItem(
              GetBaseUrl()[1] + "_DefaultHomePage",
              Math.random(450)
            );
          }}
        >
          <div className={`sidebar__item-inner ${active}`}>
            <i className="edu ic-home"></i>
            <span>Home</span>
          </div>
        </div>
      </Link>
      <Link to="/users">
        <div
          className="sidebar__item"
          onClick={() => {
            RouteClick();
            localStorage.setItem(
              GetBaseUrl()[1] + "_DefaultHomePage",
              Math.random(450)
            );
          }}
        >
          <div className={`sidebar__item-inner ${active}`}>
            <i className="edu ic-home"></i>
            <span>Users</span>
          </div>
        </div>
      </Link>
      <div>
        <div onClick={() => setOpen(!open)}>
          <div className="sidebar__item pointer">
            <div className={`sidebar__item-inner ${active}`}>
              <i className="edu ic-data-input"></i>
              <span>Data Input</span>
            </div>
          </div>
        </div>
        <Collapse in={open} className="collapse-content">
          <ul>
            <li>
              <Link to="/DataInput/Upload-Document">
                <div className="sidebar__item" onClick={() => RouteClick()}>
                  <div className={`sidebar__item-inner ${active}`}>
                    <i className="edu ic-menu-arrow"></i>
                    <span>Upload Document</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/DataInput/Tables">
                <div className="sidebar__item" onClick={() => RouteClick()}>
                  <div className={`sidebar__item-inner ${active}`}>
                    <i className="edu ic-menu-arrow"></i>
                    <span>Tables</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/DataInput/Directory">
                <div className="sidebar__item" onClick={() => RouteClick()}>
                  <div className={`sidebar__item-inner ${active}`}>
                    <i className="edu ic-menu-arrow"></i>
                    <span>Directory</span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </Collapse>
      </div>

      <SidebarChild
        collapsedNavbar={props.collapsedNavbar}
        setCollapsedNavbar={props.setCollapsedNavbar}
        RouteClick={RouteClick}
      />
      <SidebarReport
        collapsedNavbar={props.collapsedNavbar}
        setCollapsedNavbar={props.setCollapsedNavbar}
        RouteClick={RouteClick}
      />

      <Link to="/Jobs">
        <div
          className="sidebar__item"
          onClick={() => {
            RouteClick();
            localStorage.setItem(
              GetBaseUrl()[1] + "_DefaultHomePage",
              Math.random(450)
            );
          }}
        >
          <div className={`sidebar__item-inner ${active}`}>
            <img src={jobs} className="ms-1 me-1" />
            <span className="ms-2">Jobs</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Sidebar;
