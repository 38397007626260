import React, { useState } from 'react'
import { Modal, Form, Tab, Tabs } from 'react-bootstrap'
import Result from '../TableResults/Result';
import Button from 'react-bootstrap/Button';


function GenerateDashboard() {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <div>
        <Button className="rounded-pill main-button px-2 fs-8 fw-medium" variant="" onClick={handleShow}>
        Generate Dashboard
      </Button>

        <Modal centered size='xl' show={show} onHide={handleClose}>
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold'>Generated Dashboard</Modal.Title>
          <button className="btn main-button rounded-pill fw-semibold mx-3">Save as Report</button>
        </div>
        </Modal.Header>
        <Modal.Body>
        <Tabs
        defaultActiveKey="1"
        id="uncontrolled-tab-example"
        className="mb-3"
        >
        <Tab eventKey={1} active title={<span><i className="edu ic-table fs-4" /> </span>}>
            <Result/>
        </Tab>
        <Tab eventKey={2} title={<span><i className="edu ic-column-bar fs-4" /> </span>}>
            Column Chart
        </Tab>
        <Tab eventKey={3} title={<span><i className="edu ic-row-bar fs-4" /> </span>}>
            Bar Chart
        </Tab>
        <Tab eventKey={4} title={<span><i className="edu ic-pie fs-4" /> </span>}>
            Pie Chart
        </Tab>
        <Tab eventKey={5} title={<span><i className="edu ic-donut fs-4" /> </span>}>
            Donut Chart
        </Tab>
        <Tab eventKey={6} title={<span><i className="edu ic-line fs-4" /> </span>}>
            Line Chart
        </Tab>
        <Tab eventKey={7} title={<span><i className="edu ic-area fs-4" /> </span>}>
            Area Chart
        </Tab>
        </Tabs>
        </Modal.Body>
        </Modal>
    
    </div>
  )
}

export default GenerateDashboard
