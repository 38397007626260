import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../assets/svg/upload.svg";
import ChooseDirectory from "../../components/modals/ChooseDirectory";
import xls_icon from "../../assets/svg/xls.svg";
import NewTable from "../modals/NewTable";
import { Button } from "react-bootstrap";
import axios from "axios";
import GetBaseUrl from "../../functions/SetBaseUrl";
import Modal from "../modals/NewTable";
import { Link } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import check from "../../assets/images/check.svg";
import note from "../../assets/svg/note-sm.svg";
import ProgressBar from "react-bootstrap/ProgressBar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FileUpload = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedResults, setUploadedResults] = useState([]);
  const [progress, setProgress] = useState([]);
  const [done, setDone] = useState(false);
  const [fullArr, setFullArr] = useState([]);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "text/xls": [".xls"],
      "text/xlsx": [".xlsx"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 5) {
        toast.error("Max number of Files is 5", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (!props.singleUpload && acceptedFiles[i].size > 1000000000) {
          toast.error("Max File size is 1GB", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        } else if (
          !props.singleUpload &&
          acceptedFiles[i].type !== "text/csv" &&
          acceptedFiles[i].type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          toast.error("Accept only Csv/Excel files", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        } else if (
          props.singleUpload &&
          acceptedFiles[i].type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          toast.error("Accept only Excel files", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        }
      }
      setUploadedFiles(acceptedFiles);
      setProgress([50, 50, 50, 50, 50]);
      // Call your backend API endpoint to upload files
      // Call your backend API endpoint to upload files
    },
  });

  useEffect(() => {
    if (props.NeedConfirmUpload && props.confirmed) {
      let API_Url = "";
      if (props.Update && props.UpdateType && props.TableId) {
        API_Url = "/Data/" + props.UpdateType + "/" + props.TableId;
      } else {
        API_Url = "/Data/UploadFile";
      }

      for (let i = 0; i < uploadedFiles.length; i++) {
        uploadedFiles[i]["Progress"] = 75;
        const BaseUrl = GetBaseUrl()[0];
        var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
        const formData = new FormData();
        formData.append("FileToUpload", uploadedFiles[i]);

        try {
          axios({
            method: "post",
            url: BaseUrl + API_Url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data;" + uploadedFiles[i].type,
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              if (
                res.data.missingColumns &&
                res.data.missingColumns.length > 0 &&
                props.UpdateType === "AddOnUploadFile"
              ) {
                if (i === uploadedFiles.length - 1) {
                  toast.error("Columns in the file do not match", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              } else {
                if (i === uploadedFiles.length - 1) {
                  toast.success(
                    "File has been uploaded and Validation will be run automatically",
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                }
                uploadedFiles[i]["tableId"] = res.data.tableId;
                uploadedFiles[i]["tableName"] = res.data.tableName;
              }
            })
            .catch((res) => {
              toast.error(res + "", {
                position: toast.POSITION.TOP_RIGHT,
              });
            })
            .finally(() => {
              props.setShow(false);
              props.setConfirmed(false);
              props.setViewTable("");
              props.setViewTable(props.TableId);

              uploadedFiles[i]["Progress"] = 100;
            });
        } catch (error) {
          toast.error("error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  }, [props.confirmed]);

  useEffect(() => {
    if (props.NeedConfirmUpload || !props.confirmed) {
    } else if (props.singleUpload) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const formData = new FormData();
        formData.append("FileToUpload", uploadedFiles[i]);

        try {
          axios({
            method: "post",
            url:
              BaseUrl +
              "/Data/PostMapColumnFile/" +
              props.TableId +
              "/" +
              props.ColumnId,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data;" + uploadedFiles[i].type,
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                toast.success("Data Mapping File has been uploaded", {
                  position: toast.POSITION.TOP_RIGHT,
                });

                props.setReloadList(Math.random(120));
              } else {
                toast.error("error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch(() => {})
            .finally(() => {});
        } catch (error) {
          toast.error("error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      let API_Url = "";
      if (props.Update && props.UpdateType && props.TableId) {
        API_Url = "/Data/" + props.UpdateType + "/" + props.TableId;
      } else {
        API_Url = "/Data/UploadFile";
      }
      let arr = [];

      for (let i = 0; i < uploadedFiles.length; i++) {
        arr.push(75);
        const formData = new FormData();
        formData.append("FileToUpload", uploadedFiles[i]);

        //console.log(formData);

        try {
          axios({
            method: "post",
            url: BaseUrl + API_Url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data;" + uploadedFiles[i].type,
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                uploadedFiles[i]["tableId"] = res.data.tableId;
                uploadedFiles[i]["tableName"] = res.data.tableName;
              } else {
                toast.error("error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .finally(() => {
              arr[i] = 100;
              if (i === uploadedFiles.length - 1) {
                toast.success("File has been uploaded", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setFullArr(arr);
              }
            });
        } catch (error) {
          toast.error("error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  }, [uploadedFiles]);

  useEffect(() => {
    //  console.log('fullArr: '+fullArr);
    setProgress(fullArr);
  }, [fullArr]);

  useEffect(() => {
    //console.log(progress);
  }, [progress]);

  const [countOfProgess, setCountOfProgess] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCountOfProgess((oldProgress) => {
        if (100 === oldProgress) return 0;
        return Math.min(oldProgress + Math.random() * 10, 100);
      });
    }, 499);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {props.singleUpload ? (
        <Button
          className=" d-flex align-items-center mt-2 mb-4 px-0"
          variant=""
          {...getRootProps()}
        >
          <img src={upload} className="w-15" />
          <span className="ms-2 fw-semibold fs-7">
            Upload file with accepted values (.xls)
          </span>
        </Button>
      ) : (
        <>
          <ToastContainer />
          <div className="top-bg rounded-5 w-100 h-100 me-5">
            <div className="dash-border h-100 d-flex w-100  flex-column">
              <div className="px-5 pt-4">
                <span className="fw-normal fs-5 w-75 mt-3">
                  {/* Files Name with chart */}

                  <ul className="mt-4">
                    {uploadedFiles.map((file, index) => (
                      <li
                        key={file.name}
                        className="d-flex align-items-center justify-content-between fw-medium fs-5 mb-4"
                      >
                        <div className="fs-6">
                          <img src={xls_icon} className="mx-2" />
                          {file.name.length > 50
                            ? file.name.substring(0, 50) + " ..."
                            : file.name}
                        </div>
                        {!props.NeedConfirmUpload ? (
                          <div className="d-flex align-items-center">
                            {/* {parseInt(progress[index])} % */}
                            {
                              progress[index] < 100 ? (
                                <Rings color="#05BC86" height={40} width={40} />
                              ) : (
                                <img src={check} />
                              ) // Replace checked   here
                            }
                            {/* <div
                              style={{
                                display: "block",
                                width: 500,
                                padding: 10,
                              }}
                            >
                              <ProgressBar animated now={progress[index]} />
                            </div>
                            {progress[index] < 100 ? (
                              <Rings color="#05BC86" height={40} width={40} />
                            ) : null} */}
                            {/* {progress[index]<100  ?
                                        <Button variant=''><i className='edu ic-delete fs-4 red-fill mr-3'/></Button>  
                                        :
                                        null 
                                        }                                  */}
                          </div>
                        ) : null}
                      </li>
                    ))}
                  </ul>

                  {/* Files Name with chart */}
                </span>
              </div>

              <input accept=".xls,.xlsx" {...getInputProps()} />

              <div className="d-flex justify-content-between px-5 pb-4">
                <div className="my-2 w-100">
                  <Button
                    variant=""
                    className="shadow p-5 rounded-4 d-flex align-items-center w-100 justify-content-center"
                    {...getRootProps()}
                  >
                    <img src={upload} alt="a" />
                    <span className="fw-medium fs-5 mt-3 text-start mx-3">
                      Upload <br /> Document (.csv, .xls, xlsx)
                    </span>
                  </Button>
                </div>

                {!props.noDirectory ? (
                  <div className="my-2 w-100 ms-3">
                    <Link to="/DataInput/Directory">
                      <ChooseDirectory />
                    </Link>
                  </div>
                ) : null}
              </div>
              <div className="">
                <span className="fw-semibold fs-7 d-flex justify-content-center">
                  <img src={note} className="me-1" /> Note: 1st sheet will be
                  only accepted
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default FileUpload;
