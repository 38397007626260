import React, { useState } from 'react'
import { Button, ButtonGroup, Collapse, Dropdown } from 'react-bootstrap'
import DataType from './DataType';
import Success from '../modals/Success';
import Rename from '../modals/Rename';
import Delete from '../modals/Delete';

function OutputLink() {
    const [open, setOpen] = useState(false);
  return (
    <div>
        <Button onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant=''
        className='w-100 p-0'>
            <div className='d-flex justify-content-between align-content-center tbl my-2'>
           <div className='d-flex align-items-center w-70'>
                <i className='edu ic-tbl fs-7 mx-2'></i> 
                <span className='fs-7 text-truncate'>Customer Details</span>
            </div>
            <div className=''>
                <i className='edu ic-link mx-1 main-filter fs-9'></i> 
                <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle split variant="" id="dropdown-split-basic" className='edu ic-more p-0' />
                <Dropdown.Menu className='fs-7'>
                  <Dropdown.Item href="#/action-1" className='p-1 ps-3'><Success/></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-1" className='p-1 ps-3'><i className='edu ic-remove-table'> </i>Remove to Group</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3'><i className='edu ic-run'> </i>Run</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-3" className='p-1 ps-3'><Rename/></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-4" className=' p-1 ps-3'><Delete/></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
        </div>
        </Button>

        <Collapse in={open} className='collapse-content'>
        <div className='ms-3 border-start'>
      <DataType/>
      <DataType/>
      <Button variant='' className='border-button rounded-pill fw-semibold fs-7 px-5 w-100'>Add Condition</Button>
      </div>
      </Collapse>
    </div>
  )
}

export default OutputLink
