import { React, useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import back from "../../assets/svg/back.svg";
import upload from "../../assets/svg/upload_mapping.svg";
import plus from "../../assets/svg/add_condition.svg";
import add from "../../assets/svg/add-value.svg";
import reset from "../../assets/svg/retry-white.svg";
import Delete from "./DeleteReport";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import GetBaseUrl from "../../functions/SetBaseUrl";
import SingleCondition from "./SingleCondition";

function ConditionalFields(props) {
  const handleChangeItem = (event) => {};
  const [conditions, setConditions] = useState([]);
  const [conditionValues, setConditionValues] = useState([
    "",
    "",
    "",
    "",
    "",
    false,
  ]);
  const [reset, setReset] = useState(0);

  const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const BaseUrl = GetBaseUrl()[0];

  //---------------------------------------------------
  useEffect(() => {
    //console.log(props.currentColumnDataType);
    let arr = [];
    axios
      .get(BaseUrl + "/Settings/GetConditionalFormatting/" + props.ColumnId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data) {
          res.data.map((item) => {
            arr.push({
              columnId: item.columnId,
              operator: item.operator,
              columnValue: item.columnValue,
              columnAction: item.columnAction,
              isRequired: item.isRequired,
              mapping: item.mappings,
            });
          });
        } else {
          arr.push({
            columnId: "",
            operator: "",
            columnValue: "",
            columnAction: "",
            isRequired: false,
            mapping: [],
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setConditions(arr);
      });
  }, [props.viewConditionalFormatting]);
  //---------------------------------------------------
  useEffect(() => {
    // console.log(conditions);
  }, [conditions]);

  useEffect(() => {
    // console.log(conditionValues);
    let arr = [...conditions];
    if (arr[conditionValues[0]]) {
      arr[conditionValues[0]].columnId = conditionValues[1];
      arr[conditionValues[0]].operator = conditionValues[2];
      arr[conditionValues[0]].columnValue = conditionValues[3];
      arr[conditionValues[0]].columnAction = conditionValues[4];
      arr[conditionValues[0]].isRequired = /true/.test(conditionValues[5]);
    }

    setConditions(arr);
  }, [conditionValues]);

  //---- ----- --- ----- ----- ---- ---- ---- ---- ----- ---- ----
  function addCondition() {
    setConditions([
      ...conditions,
      {
        columnId: "",
        operator: "!=",
        columnValue: "",
        columnAction: "",
        isRequired: false,
        mapping: [],
      },
    ]);
  }
  //---- ----- --- ----- ----- ---- ---- ---- ---- ----- ---- ----
  function ResetAllConditions() {
    axios
      .post(
        BaseUrl + "/Settings/ResetConditionalFormatting/" + props.ColumnId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        toast.success("All conditions have been removed", {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.setViewConditionalFormatting(false);
        props.setViewDataFieldPropTable(true);
        setReset(Math.random());
      })
      .catch(() => {
        toast.info("reset Formatting error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {});
  }
  //--------------------------
  function FindReturnObj(array, Key, Value) {
    var msg;
    for (var i = array.length - 1; i >= 0; i--) {
      if (array[i][Key] === Value) {
        msg = array[i];
        break;
      }
    }
    return msg;
  }
  //---------------------------------------
  function SaveAllConditions(array) {
    //console.log(array);

    let DataTypeId = props.ColumnsOptions[0].dataTypeId;

    // let DataTypeName =
    //   props.currentColumnDataType[props.currentColumnDataType.length - 1].text;
    // console.log(props.currentColumnDataType);
    // console.log(
    //   FindReturnObj(props.currentColumnDataType, "columnId", props.ColumnId)
    //     .text
    // );
    // console.log(
    //   FindReturnObj(props.currentColumnDataType, "columnId", props.ColumnId)
    //     .dataType
    // );
    let Json = {
      columnId: props.ColumnId,
      DataTypeId: DataTypeId,
      conditions: array,
    };
    console.log(Json);

    // return false;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (
        !element.columnId ||
        !element.operator ||
        !element.columnValue ||
        !element.columnAction
      ) {
        toast.info("At least One Completed Condition is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });

        return false;
        break;
      } else if (
        element.columnAction === "Mapping" &&
        !element.mapping.length
      ) {
        toast.info("No Values in Mapping List", {
          position: toast.POSITION.TOP_RIGHT,
        });

        return false;
        break;
      }
    }

    axios
      .post(BaseUrl + "/Settings/PostConditionalFormatting", Json, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Saved Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.setViewConditionalFormatting(false);
        props.setViewDataFieldPropTable(true);
        setReset(Math.random(500));
      })
      .catch(() => {
        toast.info("Formatting error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {});
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-1">
        <div className="d-flex align-items-center">
          <Button
            className="ps-0 py-0 d-flex align-items-center"
            variant=""
            onClick={() => {
              props.setViewConditionalFormatting(false);
              props.setViewDataFieldPropTable(true);
              setReset(Math.random());
            }}
          >
            <img src={back} style={{ width: "20px" }} />
            <span className="ms-2 fs-5 fw-semibold">
              Add Conditional Formatting
            </span>
          </Button>
        </div>
        <div className="">
          <Button
            variant=""
            className="btn-secondary me-2 fw-semibold rounded-pill py-2"
            onClick={() => {
              props.setViewConditionalFormatting(false);
              props.setViewDataFieldPropTable(true);
              setReset(Math.random());
            }}
          >
            Discard
          </Button>
          <Button
            variant=""
            className="main-button fw-semibold rounded-pill py-2 me-2"
            onClick={() => ResetAllConditions()}
          >
            Reset
          </Button>
          <Button
            variant=""
            className="main-button fw-semibold rounded-pill py-2"
            onClick={() => SaveAllConditions(conditions)}
          >
            Save
          </Button>
        </div>
      </div>

      {conditions &&
        conditions.map((item, index) => (
          <SingleCondition
            key={index}
            index={index}
            TableColumns={props.TableColumns}
            id={item.id}
            columnId={item.columnId}
            operator={item.operator}
            value={item.value}
            action={item.action}
            required={item.required}
            mapping={item.mapping}
            conditions={conditions}
            setConditions={setConditions}
            reset={reset}
            setConditionValues={setConditionValues}
          />
        ))}
      {/* <div className="py-3 border-top ">
        <div className="d-flex align-items-center">
          <span className="fw-semibold mt-2 me-2 w-5">Else If</span>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Column Name
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Column 1</option>
                <option>Column 2</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-20 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">
                Operator
              </Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Yes</option>
                <option>No</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="w-25 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Value</Form.Label>
              <Form.Control
                className="field-input"
                type="text"
                placeholder="Enter Value"
              />
            </Form.Group>
          </div>
          <span className="fw-semibold mt-2 mx-2">Then</span>
          <div className="w-25 mx-2 ">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Actions</Form.Label>
              <Form.Select
                className="field-input"
                aria-label="Default select example"
              >
                <option>Select</option>
                <option>Mapping</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="">
            <Dropdown className="">
              <Dropdown.Toggle variant="" id="dropdown-basic" className="mt-3">
                <i className="edu ic-more ifmore fs-5" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-1 ps-3 fw-medium">
                  <i className="edu ic-refresh me-2" /> Reset Fields
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="p-1 ps-3 fw-medium me-2">
                  <i className="edu ic-delete me-2" /> Delete Condition
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div></div>
        </div>
      </div> */}
      <div className="w-100 mt-5">
        <hr className="w-100" />
        <Button
          variant=""
          className="main-button fw-semibold rounded-pill w-12 condition"
          onClick={() => addCondition()}
        >
          <img src={plus} /> Condition
        </Button>
      </div>
    </div>
  );
}

export default ConditionalFields;
