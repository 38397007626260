import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import add from "../../assets/svg/add-value.svg";
import delete_value from "../../assets/svg/del-value.svg";

function AddValue(props) {
  const [array, setArray] = useState(props.mappingValues);
  const [show, setShow] = useState(false);
  const [selectedModalSingleValue, setSelectedModalSingleValue] = useState("");

  const handleClose = () => {
    setShow(false);
    props.setShowMappingModal(false);
  };
  const handleShow = () => {
    setShow(true);
    props.setShowMappingModal(true);
  };

  const handleAddModalSingleValueChange = (e) => {
    setSelectedModalSingleValue(e.target.value);
  };
  function saveAllValues(array) {
    props.setMappingValues(array);
    handleClose();
  }
  //---------------------------------
  function removeSingleValue(value) {
    let arr = [...array];
    let i = arr.indexOf(value);
    if (i > -1) {
      arr.splice(i, 1);
    }
    setArray(arr);
    props.RemoveMapping(value);
  }

  //---------------------------------

  const saveSingleValue = (e) => {
    if (
      selectedModalSingleValue &&
      [...array].indexOf(selectedModalSingleValue) === -1
    ) {
      setArray([...array, selectedModalSingleValue]);
      props.setAddData([...props.AddData, selectedModalSingleValue]);
      setSelectedModalSingleValue("");
    }
  };
  //---------------------------------
  const handleKeyDownModal = (e) => {
    if (
      e.key === "Enter" &&
      selectedModalSingleValue &&
      [...array].indexOf(selectedModalSingleValue) === -1
    ) {
      setArray([...array, selectedModalSingleValue]);
      props.setAddData([...props.AddData, selectedModalSingleValue]);
      setSelectedModalSingleValue("");
    }
  };
  //---------------------------------------------------
  useEffect(() => {
    setArray(props.mappingValues);
  }, [show]);
  //---------------------------------------------------

  return (
    <div>
      <Button
        onClick={handleShow}
        variant=""
        className="main-button fw-semibold rounded-pill pt-1 my-1 me-1 fs-7 d-flex align-items-center"
      >
        View Values
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="p-3 pb-0" closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold">
              {!props.showOnly ? "Add Value Manually" : " Mapping Values "}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className=" d-flex justify-content-between align-items-center mb-2">
            <Form.Group
              className="mb-3 w-100"
              controlId="exampleForm.ControlInput1"
            >
              <div className="inputWithButton">
                <Form.Control
                  className="field-input"
                  type="text"
                  placeholder="Enter Values"
                  value={selectedModalSingleValue}
                  onChange={handleAddModalSingleValueChange}
                  onKeyDown={handleKeyDownModal}
                />
                <Button variant="" onClick={() => saveSingleValue()}>
                  <img src={add} />
                </Button>
              </div>
            </Form.Group>
          </div>

          <div className="results border-0 row overflow-auto">
            {array &&
              array.map((i, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center rounded-pill fw-medium fs-7 val_res p-1 px-2 col-4 mb-1 me-1"
                >
                  <span key={index}>{i}</span>
                  <Button
                    variant=""
                    className="px-1"
                    onClick={() => removeSingleValue(i)}
                  >
                    <img src={delete_value} />
                  </Button>
                </div>
              ))}
          </div>
        </Modal.Body>
        {!props.showOnly ? (
          <Modal.Footer className="border-0 d-flex justify-content-center">
            <Button
              className="btn-outline-danger w-30 fw-semibold rounded-pill py-2"
              variant=""
              onClick={() => setArray([])}
            >
              Reset Values
            </Button>

            <Button
              className="btn-danger w-30 fw-semibold rounded-pill py-2"
              variant=""
              onClick={handleClose}
            >
              Discard
            </Button>

            <Button
              className="main-button w-30 fw-semibold rounded-pill py-2"
              variant=""
              onClick={() => saveAllValues(array)}
            >
              Save
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    </div>
  );
}

export default AddValue;
