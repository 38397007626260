import { width } from "@mui/system";
import React, { useEffect, useState, useRef, useContext } from "react";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import styles from "./ActionBtn.module.scss";
import { IndexContext } from "../layout/Layout";

function ActionBtn({
  icon,
  title = "",
  OnClick,
  width = ["100%"],
  bg = "transparent",
  variant = "default",
  rounded,
  classname = "",
}) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext
  return (
    <Button
      variant={variant}
      className={`fw-semibold  ${rounded && "rounded-pill py-1"}  ${
        PIS[classname]
      }`}
      onClick={() => OnClick()}
      style={{
        direction: "ltr",
        backgroundColor: bg,
        marginLeft: "0px",
      }}
    >
      {icon && (
        <i
          className={`  m-2 edu ${icon && icon} ${
            !title ? " fs-3 " : "fs-5"
          }    `}
        >
          {" "}
        </i>
      )}

      {title && <span className="fs-7 fw-semibold">{title}</span>}
    </Button>
  );
}

export default ActionBtn;
