import React, { useState } from 'react'
import { Button, ButtonGroup, Collapse, Dropdown } from 'react-bootstrap'
import TableXls from '../../components/tablelist/TableXls'
import Rename from '../modals/RenameGroup'
import Delete from '../modals/DeleteGroup'




const TableGroup = ({setViewDataFilterTable, GName, setReload, setViewTable, Index, Title, EachGroupTables, setViewDataFieldsTable, IsViewTable, runValidation, GId, setReloadLists, syncTable, runValidations, syncTables}) => { 
  const [open, setOpen] = useState(false);
  return (
    <div index={Index} >
        
           <Button onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              variant=''
              className='w-100 p-0'>
                <div className='d-flex justify-content-between align-content-center group my-2'>
                <div className='d-flex align-items-center w-75'>
                      <i className='edu ic-group fs-7 mx-2'></i> 
                      <span className='fs-7 text-truncate'>{Title}</span>
                  </div>
                  <div className='d-flex align-items-center'>
                      <i className='edu ic-down mx-1 fs-11'></i> 
                      <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle split variant="" id="dropdown-split-basic" className='edu ic-more p-0' />
                      <Dropdown.Menu className='fs-7 more-drop'>
                        <Dropdown.Item  className='p-1 ps-3' onClick={() => syncTables(GId)}><i className='edu ic-sync' > </i>Sync all</Dropdown.Item>
                        <Dropdown.Divider />
                        {/* <Dropdown.Item  className='p-1 ps-3'><i className='edu ic-add-table'> </i>Add Table</Dropdown.Item>
                        <Dropdown.Divider /> */}
                        <Dropdown.Item  className='p-1 ps-3' onClick={() => runValidations(GId)} ><i className='edu ic-edit' > </i>Run</Dropdown.Item>
                        <Dropdown.Divider />


                        <>
                  <Dropdown.Item  className='p-1 ps-3 w-100'><Rename GName={GName} GId= {GId}  setReload={setReloadLists} /></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item  className='p-1 ps-3 w-100'><Delete GId= {GId} setReload={setReloadLists}  /></Dropdown.Item>
                  </>


                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
            </Button>

            <div className=''>
            <Collapse in={open} className=''>
             <div className='ms-3 border-start'>
             {EachGroupTables ? 
              
              EachGroupTables.map((item, index) =>  
                 
                   

                 <div className='h-100' key={index}>
                     <TableXls 
                      setViewTable={setViewTable}
                      setViewDataFieldsTable={setViewDataFieldsTable}
                      setViewDataFilterTable={setViewDataFilterTable}

                      IsViewTable= {IsViewTable}
                      Title={item.tableName1}
                      tableId= {item.tableId}
                      IsPlatformTable={item.tableType}

                      Index={index} 
                      runValidation = {runValidation}

                      syncTable = {syncTable}

                      key={index}

                      WithCheckbox={false}
                      IsGroup={GId}
                      setReloadLists = {setReloadLists}
                     /> 
                 </div> 
               )
     : null}
            </div>
            </Collapse>
            </div>

          
    </div>


  )
}

export default TableGroup



