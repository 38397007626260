import React, { useState } from "react";
import TableXls from "../../components/tablelist/TableXls";
import { Button, Collapse } from "react-bootstrap";
import TableBb from "../tablelist/TableBb";
import TableOffice from "../tablelist/TableOffice";
import TableCanvas from "../tablelist/TableCanvas";
import List from "./List";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TableList = ({
  setReload,
  viewRelationship,
  relationshipsList,
  setReloadList,
  setMainJsonBody,
  setWork_Tables,
  setNewName,
  setShowLinks,
  setShowLayout,
  setRelationshipName,
  setRelationshipId,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className=" d-flex flex-column justify-content-between h-100 ">
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant=""
        className="w-100 p-0"
      >
        <div className="d-flex justify-content-between align-items-center mb-1 ">
          <div className="d-flex align-items-center ">
            <span className="fs-7 fw-medium third">RELATIONSHIPS </span>
          </div>
          <Link to="/NewRelationships/Create">
            <Button className="p-0  " variant="">
              <i class="edu ic-plus fs-4"></i>
            </Button>
          </Link>
        </div>
      </Button>
      <Collapse in={!open} className="collapse-content">
        <div className="">
          {relationshipsList.length > 0 &&
            relationshipsList.map((item, index) => (
              <List
                key={index}
                R_Id={item.relationshipId}
                Title={item.relationshipName}
                viewRelationship={viewRelationship}
                setReloadList={setReloadList}
                setMainJsonBody={setMainJsonBody}
                setWork_Tables={setWork_Tables}
                setNewName={setNewName}
                setReload={setReload}
                setRelationshipName={setRelationshipName}
                setShowLinks={setShowLinks}
                setShowLayout={setShowLayout}
                setRelationshipId={setRelationshipId}
              />
            ))}
        </div>
      </Collapse>
    </div>
  );
};

export default TableList;
