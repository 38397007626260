import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  fromValue,
  toValue,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  minCriteriaPlaceholder,
  maxCriteriaPlaceholder,
  setRecallCriteria,
  recallCriteria,
  disabled,
}) {
  const [range, setRange] = useState([
    specialFunc(value, "min"),
    specialFunc(value, "max"),
  ]);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;

    let from = range[0];
    let to = range[1];
    let ch = e.target.attributes.rangeCriteriaAttr.value;

    if (ch === "rangeFrom") {
      if (recallCriteria === "Inbetween") {
        setRange([e.target.value, to]);
        setMin(e.target.value);
        val = e.target.value + "<>" + to;
      } else if (recallCriteria === "<") {
        setRange([e.target.value, ""]);
        setMin(e.target.value);
        val = "<" + e.target.value;
      } else if (recallCriteria === ">") {
        setRange([e.target.value, ""]);
        setMin(e.target.value);
        val = ">" + e.target.value;
      } else if (recallCriteria === "=") {
        setRange([e.target.value, ""]);
        setMin(e.target.value);
        val = "=" + e.target.value;
      }
    } else {
      setRange([from, e.target.value]);
      setMax(e.target.value);
      if (recallCriteria === "Inbetween") {
        val = from + "<>" + e.target.value;
      }
    }
    //console.log(val);
    res = name;
    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  function specialFunc(params, side = "") {
    let final = 0;

    if (params && recallCriteria === "Inbetween" && side === "min") {
      final = params.split("<>")[0];
    } else if (params && recallCriteria === "Inbetween" && side === "max") {
      final = params.split("<>")[1];
    } else if (params && recallCriteria !== "Inbetween" && side === "min") {
      final = params.substring(1);
    }
    return parseInt(final, 10);
  }

  //---------------------------------------------------
  useEffect(() => {
    //console.log(recallCriteria);
    if (recallCriteria === "none") {
      setMin(specialFunc(value, "min"));
      setMax(specialFunc(value, "max"));
    } else {
      setMin(specialFunc(value, "min"));
      setMax(specialFunc(value, "max"));
    }
  }, [recallCriteria]);

  return (
    <div className={disabled}>
      <div className="row d-flex1 align-items-center1">
        <div className={"col-6 hide minCriteria"}>
          <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
            <Form.Control
              className="field-input2 rangeFromCriteria"
              rangeCriteriaAttr="rangeFrom"
              type="number"
              placeholder={minCriteriaPlaceholder}
              value={min}
              onChange={handleChange}
              name={Id}
            />
          </Form.Group>
        </div>
        {/* <div className="col-1 p-0 d-flex flex-column justify-content-center align-items-center hide maxCriteria">
          <Form.Label className="fw-semibold fs-7 mb-0 mt-3 hide maxCriteria">
            -
          </Form.Label>
        </div> */}
        <div className={"col-6 hide maxCriteria"}>
          <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
            <Form.Control
              className="field-input2  rangeToCriteria"
              rangeCriteriaAttr="rangeTo"
              type="number"
              placeholder={maxCriteriaPlaceholder}
              value={max}
              onChange={handleChange}
              name={Id}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}
export default Component;
