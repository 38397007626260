import React from 'react'
import PublicTable from '../components/API_Table/main'
import getProp from '../functions/TableFuncs'
import GetBaseUrl from '../functions/SetBaseUrl'
const BaseUrl = GetBaseUrl()[0]   ;

const Dashboard = () => {

  return (
    <div className = "rounded-5 main-bg min-vh-85">

        {/* <PublicTable
        loadingText=     {getProp('Dashboard','LoadingText')}
        TableTitle=      {getProp('Dashboard','TableTitle')}
        TableUrl=        {BaseUrl+getProp('Dashboard','TableUrl')}  
        TableHead=       {getProp('Dashboard','TableHead','array')}     
        BodyCol=         {getProp('Dashboard','BodyCol','array')}
        AllowEdit =      {getProp('Dashboard','AllowEdit','array')}
        InputsTitle =    {getProp('Dashboard','InputsTitle','array')}
        InputsType =     {getProp('Dashboard','InputsType','array')}
        modalContentType={getProp('Dashboard','modalContentType')}
        lastColumn
        withOutBorder
        /> */}

    </div>
  )
}

export default Dashboard
