import { render } from "@testing-library/react";
import { React, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Form } from "react-bootstrap";

const DataType = (props) => {
  const [Columns, setColumns] = useState([]);
  const [ch, setCh] = useState(true);

  //----------------------------------------------------
  function FindVisibleObj(array, Key, Value, visible) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        // console.log(array[i]);
        array[i].visible = visible;
      }
    }
  }
  //----------------------------------------------------
  function FindIndexInObj(array, Key, Value) {
    var msg;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i;
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------------
  function FindDeleteFromObj(array, Key1, Value1) {
    let res = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key1] === Value1) {
        array.splice(i, 1);
        res = true;
      }
    }

    return res;
  }
  //----------------------------------------------------

  const handleChange = (e) => {
    let isChecked = e.target.checked;
    let value = e.target.value;

    let updatedObj = { ...props.obj };
    let TableIndex = FindIndexInObj(
      updatedObj.Tables,
      "tableId",
      props.tableId
    );
    let ColumnIndex = FindIndexInObj(
      updatedObj.Tables[TableIndex].columns,
      "columnId",
      value
    );
    updatedObj.Tables[TableIndex].columns[ColumnIndex].isVisible = isChecked;
    setCh(isChecked);

    props.setObj(updatedObj);
  };

  //----------------------------------------------------
  useEffect(() => {
    if (
      props.EditReportIdHead.length > 0 &&
      props.EditReportIdHead.indexOf(props.columnId) > -1
    ) {
      setCh(true);
    } else if (
      props.EditReportIdHead.length > 0 &&
      props.EditReportIdHead.indexOf(props.columnId) === -1
    ) {
      setCh(false);
    }
  }, [props.EditReportIdHead, props.open]);

  //----------------------------------------------------

  return (
    <div>
      <div className="d-flex align-items-center  w-75">
        <Form.Check
          id={props.index + 1}
          type="checkbox"
          l={props.columnName}
          label={props.columnName}
          className="ms-2 mb-2 w-100 fs-7 text-truncate"
          onChange={handleChange}
          value={props.columnId}
          table={props.tableId}
          checked={ch}
        />
      </div>
    </div>
  );
};

export default DataType;
