import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  value,
  options = [],
  optionsValues = [],
  defaultClassName = "",
  defaultHide = "",
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  optionsType,
  RestrictionsList,
  setRestrictionsList,
  disabled,
}) {
  const handleChange = (e) => {
    let res,
      str = "",
      val;
    let name = e.target.name;

    if (optionsType === "Restrictions") {
      if ([...RestrictionsList].indexOf(e.target.value) === -1) {
        setRestrictionsList([...RestrictionsList, e.target.value]);
      }
      for (let i = 0; i < [...RestrictionsList, e.target.value].length; i++) {
        const element = [...RestrictionsList, e.target.value][i];
        str += element;
      }
    } else {
      str = e.target.value;
    }

    res = name;
    val = str;
    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  return (
    <div className={defaultHide + " " + defaultClassName + " " + disabled}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="fw-semibold fs-7 mb-0">{name}</Form.Label>
        <Form.Select
          className="field-input"
          aria-label="Default select example"
          onChange={handleChange}
          name={Id}
        >
          <option selected={!value && value !== null ? "selected" : ""}>
            Select{value}
          </option>
          {options.map((item, index) => (
            <option
              key={index}
              value={optionsValues[index] ?? item}
              selected={value ? "selected" : ""}
            >
              {item}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </div>
  );
}
export default Component;
