

const CheckSession = (Server_type) => {
   
  
  var E_DateTime = localStorage.getItem(Server_type+'_Edu_AuthTokenExpiry') ;
  var myDate = new Date(localStorage.getItem(Server_type+'_Edu_AuthTokenExpiry'));
  var result = myDate.getTime();

  // console.log('tokenExpiry: ' +   E_DateTime  );
  // console.log(result);


  // console.log(new Date());
  // console.log(Date.now());


   if(Date.now()>result){
      localStorage.setItem(Server_type+"_Edu_AuthToken", '');
   }
  

   return  localStorage.getItem(Server_type+'_Edu_AuthToken')    ;

}




export default CheckSession
