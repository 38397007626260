import React, { useEffect, useState, useRef, useContext } from "react";
import { styled } from "@mui/system";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import "./Table.css";

import styles from "./Table.module.scss";
import { IndexContext } from "../../components/layout/Layout";

export default function TableCustomized(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext

  const [page, setPage] = React.useState(props.tablePage);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(props.limit));

  const [searchTerm, setSearchTerm] = useState("");
  const [searchNormalResults, setSearchNormalResults] = useState(props.data);

  //-------------------------------------------------------

  function FindResultsInTable(array, Value) {
    let keys = props.tableHeader;
    let arr = [];
    for (var j = 0; j < array.length; j++) {
      let element = array[j];
      let len = true;
      for (let i = 0; i < keys.length && len; i++) {
        if (
          element[keys[i].originalName] &&
          element[keys[i].originalName]
            .toLowerCase()
            .includes(Value.toLowerCase())
        ) {
          arr.push(array[j]);
          len = false;
        }
      }

      if (j === array.length - 1) {
        setSearchNormalResults(arr);
      }
    }
  }

  //-------------------------------------------------------

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchNormalResults(props.data);
    } else if (props.normalTable) {
      FindResultsInTable(props.data, event.target.value);
    }
  };

  const [dataShow, setDataShow] = useState(searchNormalResults);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - searchNormalResults.length)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    props.setTablePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    props.setTablePageSize(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    // console.log(page);
  }, [page]);

  return (
    <div className={` `} style={{ position: "relative", minHeight: "80vh" }}>
      {props.headerBtns && (
        <div className={`${PIS["row"]}`}>
          <div
            className={`noPadding tableTitle ${PIS["container-100-50-100"]}`}
          >
            {props.normalTableName}
          </div>

          <div
            className={`noPadding tableSearchInput-container ${PIS["container-50-50-100"]}`}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={handleChange}
              placeholder="| Search.."
              className={
                props.normalTable ? "TableSearchInput" : "TableSearchInput hide"
              }
            />
          </div>

          <div
            className={`noPadding headerControlBtns ${PIS["container-50-50-100"]}`}
          >
            {props.headerBtns && props.headerBtns.map((item) => item)}
          </div>
        </div>
      )}

      <div className="table-wrapper overflow-x-auto px-1 w-100 row">
        <table
          className="table table-bordered mt-4"
          aria-label="custom pagination table"
        >
          <thead>
            <tr className="fs-7 fw-semibold">
              {props.tableHeader.map((item, index) => (
                <th key={item.name} scope="col" className="fw-semibold">
                  {item.name}
                </th>
              ))}
              {props.withActionColumn && (
                <th scope="col" className="fw-semibold" align="center">
                  <center>{props.withActionColumn}</center>
                </th>
              )}
            </tr>
          </thead>

          <tbody className="fs-8">
            {searchNormalResults &&
              searchNormalResults.map((item, index) => (
                <tr
                  key={index}
                  style={{ height: 34 * emptyRows }}
                  onClick={() => props.setSelectedUser(item)}
                >
                  {props.tableHeader.map((i) => (
                    <td key={i.originalName}>{item[i.originalName]}</td>
                  ))}
                  {props.withActionColumn && (
                    <td align="center">
                      <center>
                        {props.actionBtns &&
                          props.actionBtns.map((item) => item)}
                      </center>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <span className="table table-borderless mt-4 fs-8">
        <tfoot>
          <tr>
            {dataShow && dataShow.length > 0 ? (
              <CustomTablePagination
                rowsPerPageOptions={[
                  5,
                  props.limit !== 10 ? props.limit : 10,
                  25,
                  50,
                  { label: "All", value: -1 },
                ]}
                colSpan={props.tableHeader.length}
                count={props.rowsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                className="paging-stick"
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </tr>
        </tfoot>
      </span>
    </div>
  );
}

const blue = {
  50: "#F0F7FF",
  200: "#A5D8FF",
  400: "#3399FF",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 30%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
  }
  `
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  
    width:100% ;
    position: absolute;
    bottom: -30px;
    @media (min-width: 768px) {
    }


  & .${classes.spacer} {
    display: none;
  }


  & .${classes.toolbar}  {
    display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    gap: 10px;
    border:0px solid green;
    flex-wrap: wrap;



    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
    width: 12%;
    display:block;
    @media (max-width: 1000px) {
          width: 20%;
    }       
    @media (max-width: 768px) {
            display:none;
    }    
  }

  & .${classes.select}{
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    width: 7%;
    display:block;
    @media (max-width: 768px) {
            display:none;
    }
    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }

  & .${classes.displayedRows} {
    margin: 0;
          display:block;

    @media (max-width: 768px) {
      display:none;
    }
    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  `
);
