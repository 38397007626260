import React from 'react'

const DataInputs = () => {
  return (
    <div className = "rounded-5 main-bg min-vh-85">
      <div className = "top-bg rounded-5 min-vh-85">

        </div>
        </div>
  )
}

export default DataInputs
