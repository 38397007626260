import React, { useEffect, useContext } from "react";
import { Handle, Position, useStore } from "reactflow";
import header from "../../assets/svg/header.svg";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

export default function CustomNode({ id, data }) {
  let array = [...data.columns];
  //RTFR : Remove Table From Relationship
  return (
    <div className="customNode">
      <Handle type="target" position={Position.Left} />
      <div className="rel-card mx-0 rounded-1 text-truncate border border-dark">
        <div className="rel-card_header d-flex align-items-center justify-content-between fw-semibold text-truncate">
          <span className="fw-semibold text-truncate fs-9">
            {data ? data.label : id}
          </span>
          <i
            className="edu ic-close fs-10 RemoveTable"
            onClick={() => data.setRandom1(id)}
            style={{ backgroundColor: data.highlight }}
          ></i>
        </div>

        <div className="" style={{ maxHeight: "300px", overflowY: "auto" }}>
          {array &&
            array.map((item, index) =>
              index ? (
                <div
                  className={"col-12 px-0 NodeColumn " + item.id}
                  key={item.id}
                >
                  <div className="p-2 pt-1 fw-medium text-truncate fs-10">
                    <img src={header} className="me-2" />
                    {item.name}
                  </div>
                </div>
              ) : null
            )}
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
}
