import { React, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import find from "../../assets/svg/find-blk.svg";
import prev from "../../assets/svg/prev.svg";
import next from "../../assets/svg/next.svg";
import find_replace from "../../assets/svg/find-blk.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GetBaseUrl from "../../functions/SetBaseUrl";

function FIndNReplace(props) {
  const [show, setShow] = useState(false);
  const [matchType, setMatchType] = useState("Match Case");
  const [find, setFind] = useState(null);
  const [replace, setReplace] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const BaseUrl = GetBaseUrl()[0];
  const [Json, setJson] = useState(null);

  //----------------------------------------------
  useEffect(() => {
    setJson({
      TableId: props.TableId,
      ColumnId: props.ColumnId,
      matchType: matchType,
      Find: find,
      Replace: replace,
    });
  }, [matchType, find, replace]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    if (name === "Find") setFind(e.target.value);
    if (name === "Replace") setReplace(e.target.value);
  };

  const handleChange = (e) => {
    let isChecked = e.target.checked;
    let value = e.target.value;

    if (isChecked) {
      setMatchType(value);
    } else {
      setMatchType("");
    }
  };

  //--------
  function saveFindReplace() {
    // console.log(Json);
    try {
      axios({
        method: "post",
        url: BaseUrl + "/Data/FindReplaceData",
        data: Json,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          toast.success("Replaced all successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShow(false);
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        });
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  //------------------------------------------------------

  return (
    <>
      <Button
        className="filter-btn rounded-circle d-flex justify-content-center align-items-center px-2"
        variant={props.isFindReplace ? "primary" : ""}
        onClick={handleShow}
      >
        {props.onlyIcon ? (
          <img src={find_replace} />
        ) : (
          <div className="w-100 d-flex">
            <img src={find_replace} />
            {/* <span className='ms-1 fw-semibold'>Find & Replace</span>      */}
          </div>
        )}
      </Button>

      <Modal
        dialogClassName="ms-0 my-0"
        contentClassName="min-vh-100"
        className="pe-0"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="p-3 pb-0" closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold fs-7">
              Find & Replace
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">Find</Form.Label>
            <Form.Control
              className="field-input"
              type="text"
              placeholder="Enter"
              name="Find"
              onChange={handleChangeText}
            />
            <div className="row mt-3 fs-7">
              <div className="col-6">
                <input
                  name="Match"
                  type="radio"
                  value={"Match Case"}
                  className="mb-2 me-2 text-truncate"
                  onChange={handleChange}
                  defaultChecked
                />
                Match Case
              </div>
              <div className="col-6">
                <input
                  name="Match"
                  type="radio"
                  value={"Match Whole Word"}
                  className="mb-2 me-2 text-truncate"
                  onChange={handleChange}
                />
                Match Whole Word
              </div>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">Replace</Form.Label>
            <Form.Control
              className="field-input"
              type="text"
              placeholder="Enter"
              name="Replace"
              onChange={handleChangeText}
            />
          </Form.Group>

          {/* <div className='d-flex align-items-center'>
            <Button className='px-1' variant=''><img src ={prev}/></Button>
            <Button className='px-1' variant=''><img src ={next}/></Button>
            <div className=''>
                <span className='fw-medium fs-6'>Results 0 of 0</span>
            </div>
        </div> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="main-button rounded-pill fw-semibold w-100"
            variant=""
            onClick={() => saveFindReplace()}
          >
            Replace All
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FIndNReplace;
