import {React, useEffect, useState } from "react";
import './relationship.css'
import RelationshipCardColumn from './RelationshipCardColumn'
import { Button } from 'react-bootstrap'


const RelationshipCard = (props) => {

  const [columnsCells, setColumnsCells] = useState(props.tableHead)
  const [updateColumnSelection, setUpdateColumnSelection] = useState(false)


  //----------------------------------------------------
  useEffect(() => { 

    if(!updateColumnSelection){

            for (let i = 0; i < columnsCells.length; i++) {
              if(props.SelectedColumn===columnsCells[i].columnId){
        
                columnsCells[i].Selected=true
              }
              else{
                columnsCells[i].Selected=false
              }
            
          }

    }
  
  }, [updateColumnSelection])
  
  
  //----------------------------------------------------
useEffect(() => { 

  //  console.log(columnsCells);
  
  }, [columnsCells])
  //----------------------------------------------------

  


  return (
    <>
            <>
            <div className='w-5 rounded-top'></div>
            <div className='rel-card w-15 rounded-3 px-0 h-100 mb-5 '>
              <div className='rel-card_header d-flex align-items-center justify-content-between '>
              <h6 className='mb-0 fw-medium text-truncate w-75'>{props.tableName}</h6>
                <Button variant='' className='p-0'  onClick={() => props.removeFromRelationship(props.TableId)}><i className='edu ic-close fs-7 white-fill'></i></Button>

              </div>
              <ul className='p-0 fields'>

                {columnsCells && columnsCells.map((item,index) => (

                <RelationshipCardColumn
                key={index}
                name={item.columnName}
                ColumnId={item.columnId}
                Selected={item.Selected}
                setMainJsonBody={props.setMainJsonBody}
                MainJsonBody={props.MainJsonBody}
                Sequence={props.Sequence}
                setColumnsCells={setColumnsCells}
                setUpdateColumnSelection={setUpdateColumnSelection}
                />


                ))}


              </ul>
            </div>
            {/* <div className='w-25 rounded-top'></div> */}
            </>

    </>
    )
}

export default RelationshipCard
