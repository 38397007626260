import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function SelectDataTypes(props) {

    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOption1, setSelectedOption1] = useState([]);
    const [selectedOption2, setSelectedOption2] = useState([]);
    const [dataOptions, setDataOptions] = useState([]);
    const [dataOptions1, setDataOptions1] = useState([]);
    const [link, setLink] = useState({});
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");
    const [tableHead, setTableHead] = useState([]);
    const [parentTable, setParentTable] = useState([]);



//------------------------------------------------------
useEffect(() => {


  let t=[]
      for (let index = 0; index < props.tables.length; index++) {
        const item = props.tables[index];
         t.push({value: item.value,text: item.text})
        
      }

            
      setDataOptions(t)

}, [props.tables])

//----------------------------------------------------
useEffect(() => { 

setSelectedOption('');   
setSelectedOption1('');   
setSelectedOption2(''); 

  }, [props.ResetNow]) 


//------------------------------------------------------
const handleChange = e => { 


    setSelectedOption1('');   
    setSelectedOption2(''); 
    setParentTable( FindIn(props.MainJsonBody,'tableId',e.value) )
    if(props.Left){
      props.setRefTable(e.value)
      props.setRefTableName(e.text)
    }

    props.setChanged(e)
    props.setFrom(props.Left)

    setSelectedOption(e);
        let tableHead = [];
        axios
        .get( BaseUrl  +  '/Data/GetColumnsByTable/'+ e.value, {
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
        })
        .then((res) => {
          if(res.data){
            res.data.columns.map((item, index) =>  tableHead.push(       {value: item.columnId,text: item.columnName,TableId: e.value,TableName: res.data.tableName}    ))
          }
        }).finally(() => {  setDataOptions1(tableHead) }) ;
}
//------------------------------------------------------
useEffect(() => {

let FullLink= {"TableId": props.linkTableId,"TableName": props.linkTableName,"ByColumn":props.byColumn ,"columnId":props.byColumn ,
               "ByColumnName":props.byColumnName ,"ToColumn":props.toColumn, "ToColumnName":props.toColumnName,
               "Target":[{"targetTableId": props.linkTableId, "targetColumnId":props.toColumn, "targetTable": props.linkTableName, "targetColumn":props.toColumnName}],
               "target":[{"tableId": props.linkTableId, "columnId":props.toColumn}] 
              
              }  

            if(FullLink.TableId ){
              props.setRefJson(FullLink)
            }

}, [props.addJson])
//------------------------------------------------------
  const handleChange1 = e => { 
    setSelectedOption1(e);
    setSelectedOption2(e);


      if(props.Left)  {
        props.setByColumn(e.value)
        props.setByColumnName(e.text)

      }
      else{
        props.setLinkTableId(e.TableId)
        props.setLinkTableName(e.TableName)
        props.setToColumn(e.value)
        props.setToColumnName(e.text)
      }

}
//----------------------------------------------------
function FindIn(array,Key,Value ) {
    let res=[]
    for (var i = 0; i < array.length; i++){
        if (array[i][Key] === Value){
             res= array[i]
             break
        }
    }  
    return res
  }  
























  return (
    <div className="border-0 fs-7 p-0">
                <Select
                  placeholder="Select Table"
                  value={selectedOption}
                  options={dataOptions}
                  onChange={handleChange}
                  className = "rounded-pill border mb-3"
                  getOptionLabel={e => (
                    <div className='fs-7 py-2'  style={{ display: 'flex', alignItems: 'center' }}>
                      {e.icon}
                      <span className='px-1 py-0'  style={{ marginLeft: 5 }}>{e.text}</span>
                    </div>
                  )}
                />

              <Select
                placeholder="Select Table Header"
                value={props.Left ? selectedOption1 : selectedOption2}
                options={dataOptions1}
                onChange={handleChange1}
                className = "rounded-pill border mb-2"
                getOptionLabel={e => (
                  <div className='fs-7 py-2'  style={{ display: 'flex', alignItems: 'center' }}>
                    {e.icon}
                    <span className='px-1 py-0'  style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
    </div>
  );



}
export default SelectDataTypes;