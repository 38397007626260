import { React, useEffect, useState, useRef } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { useLottie } from "lottie-react";
import loading_lottie from "../../assets/lottie/loading.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const TableResult = ({
  onlyView,
  TableId,
  tableRowsDefault,
  setViewDataFieldsTable,
  setViewTable,
  OnlyErrorRows,
  HeaderType,
  DownloadExcel,
  tableRef,
  Syncing,
  exportTable,
}) => {
  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  const [LastUpdate, setLastUpdate] = useState("");
  const [IsScheduled, setIsScheduled] = useState("");
  const [tableOriginalHead, setTableOriginalHead] = useState([""]);

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const options = {
    animationData: loading_lottie,
    loop: true,
  };

  const { View } = useLottie(options);

  useEffect(() => {
    let tableHead = [];
    let tablebodyCol = [];
    let tableOriginalHead = [];

    if (TableId) {
      setTAbleUrl(BaseUrl + "/Report/GetReportById/" + TableId);

      axios
        .get(BaseUrl + "/Report/GetReportById/" + TableId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          setTableName(res.data.reportName);
          setLastUpdate(res.data.lastUpdated);
          setIsScheduled(res.data.IsScheduled);
          //  console.log(res.data.columns);
          // res.data.columns.map((item) => tableOriginalHead.push({ "tableName": item['tableName'], "columnName": item['originalName'] }  )                                     )

          // res.data.columns.map((item, index) => tableHead.push( item['columnName'] )     )
          // res.data.columns.map((item, index) => tablebodyCol.push( item['originalName'] )               )
          //  console.log(res.data);
          res.data.columns.map((item, index) => {
            tableHead.push({
              tableId: item["tableId"],
              columnName: item["columnName"],
            });

            tablebodyCol.push({
              tableId: item["tableId"],
              columnName: item["originalName"],
            });

            tableOriginalHead.push({
              tableName: item["tableName"],
              columnName: item["originalName"],
            });
          });
        })
        .catch(() => {
          toast.error("Relationship of this report is not exist", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          setTableHead(tableHead);
          setBodyCol(tablebodyCol);
          setTableOriginalHead(tableOriginalHead);
        });
    }
  }, [TableId]);

  function ExportReport(TableId) {
    axios
      .get(BaseUrl + "/Report/GetFullReportByReportId/" + TableId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {
        toast.error("Error: " + error, { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {});
  }

  return (
    <>
      {tableUrl ? (
        <PublicTable
          loading={View}
          TableTitle={tableName}
          TableId={TableId}
          TableUrl={tableUrl}
          TableHead={tableHead}
          BodyCol={bodyCol}
          lastColumn={false}
          withOutBorder={false}
          defaultRows={tableRowsDefault ? tableRowsDefault : "10"}
          withHeader={HeaderType}
          firstColumn={false}
          Internal={true}
          ExportReport={ExportReport}
          ReportTable
          IsScheduled={IsScheduled}
          setViewDataFieldsTable={setViewDataFieldsTable}
          setViewTable={setViewTable}
          DownloadExcel={DownloadExcel}
          tableRef={tableRef}
          onlyView={onlyView}
          ReportColumn
          TableOriginalHead={tableOriginalHead}
          LastUpdate={LastUpdate}
          Syncing={Syncing}
          exportTable={exportTable}
        />
      ) : null}
    </>
  );
};

export default TableResult;
