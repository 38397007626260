import { React, useEffect, useState } from "react";
import RelationshipList from "../components/relationship/RelationshipList";
import Actions from "../components/relationship/NewActions";
import TableList from "../components/UploadedTable/TableList";

import GetBaseUrl from "../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RelationshipWorkSpace from "./RelationshipWorkSpace";

const Relationships = () => {
  const [relationshipsList, setRelationshipsList] = useState([]);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );
  const [reload, setReload] = useState(null);
  const [Work_Tables, setWork_Tables] = useState([]);
  const [MainJsonBody, setMainJsonBody] = useState([]);
  const [RelationshipName, setRelationshipName] = useState("");
  const [relationshipId, setRelationshipId] = useState("");
  const [relationshipLinks, setRelationshipLinks] = useState([]);

  const handleChange = (event) => {
    setRelationshipName(event.target.value);
  };
  const [tablesList, setTablesList] = useState([]);

  //----------------------------------------------------
  function FindObj(array, Key, Value) {
    var msg = "";
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        array.splice(i, 1);
        msg = "Table is exist";
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------------
  function AddTableToWorkPlace(
    TableId,
    isArray = false,
    array = [],
    links = []
  ) {
    if (isArray) {
      let WT = [];
      setRelationshipLinks(links);

      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        let Found = FindObj(WT, "TableId", element);
        if (!Found) {
          axios
            .get(BaseUrl + "/Data/GetColumnsByTable/" + element, {
              headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
              },
            })
            .then((res) => {
              WT.push({
                TableId: element,
                TableTitle: res.data.tableName,
                TableBody: res.data.columns,
              });
            })
            .finally(() => {
              if (WT.length === array.length) {
                setWork_Tables(WT);
              }
            });
        }
      }
    } else {
      let WT = [...Work_Tables];
      let Found = FindObj(WT, "TableId", TableId);
      if (TableId && !Found) {
        axios
          .get(BaseUrl + "/Data/GetColumnsByTable/" + TableId, {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            WT.push({
              TableId: TableId,
              TableTitle: res.data.tableName,
              TableBody: res.data.columns,
            });
          })
          .finally(() => {
            setWork_Tables(WT);
          });
      } else if (TableId && Found) {
        toast.warning(Found, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }
  //----------------------------------------------
  function saveNewRelationship(name) {
    let Body = [...MainJsonBody];

    let obj = {
      relationShipId: relationshipId,
      relationShipName: name,
      links: Body,
    };
    // console.log(obj);
    // return false;
    if (!name) {
      toast.error("Relationship name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (obj.links.length < 1) {
      toast.error("Add Tables to Relationship", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    axios
      .post(BaseUrl + "/Relations/CreateRelationship", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setMainJsonBody([]);
        setWork_Tables([]);
        setRelationshipId(null);
        setRelationshipName(null);
        setReload(Math.random);
      });
  }
  //------------------------------------------
  function viewRelationship(R_Id) {
    setMainJsonBody([]);
    setWork_Tables([]);
    let MJB = [];
    let tables = [];
    let links = [];

    if (R_Id) {
      axios
        .get(BaseUrl + "/Relations/GetRelationshipById/" + R_Id, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          setRelationshipId(R_Id);
          setRelationshipName(res.data.relationshipName);
          for (let i = 0; i < res.data.tables.length; i++) {
            const element = res.data.tables[i];
            tables.push(element.tableId);
          }
          links = res.data.links;
        })
        .catch((res) => {
          toast.error("Tables of this relationship are missing", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          setMainJsonBody(MJB);
          AddTableToWorkPlace("", true, tables, links);
        });
    }
  }
  //------------------------------------------
  useEffect(() => {
    //------------------
    axios
      .get(BaseUrl + "/Relations/GetAllRelationships", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setRelationshipsList(res.data);
        } else {
          setRelationshipsList([]);
        }
      })
      .finally(() => {});
    //------------------
    axios
      .get(BaseUrl + "/Data/GetTablesAll", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setTablesList(res.data);
      })
      .finally(() => {});
    //------------------
  }, [reload, RelationshipName]);
  //------------------------------------------

  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-4 col-xl-3 px-0 pe-3">
            <div className="top-bg p-3 rounded-5 min-vh-100">
              <div className="row d-flex flex-column h-100">
                <div className="col-6 w-100">
                  <RelationshipList
                    renderItem={renderItem}
                    relationshipsList={relationshipsList}
                    viewRelationship={viewRelationship}
                    setMainJsonBody={setMainJsonBody}
                    setWork_Tables={setWork_Tables}
                    setRelationshipName={setRelationshipName}
                    setReload={setReload}
                    setRelationshipId={setRelationshipId}
                  />
                  {relationshipId ? (
                    <TableList
                      renderItem={renderItem}
                      tablesList={tablesList}
                      CreateRelationship={true}
                      AddTableToWorkPlace={AddTableToWorkPlace}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-9 px-0 ">
            <div className="rel-header">
              <Actions
                saveNewRelationship={saveNewRelationship}
                newName={RelationshipName}
                handleChange={handleChange}
                relationshipId={relationshipId}
              />
            </div>

            <div className="rel-area">
              <RelationshipWorkSpace
                MainJsonBody={MainJsonBody}
                setMainJsonBody={setMainJsonBody}
                Work_Tables={Work_Tables}
                setWork_Tables={setWork_Tables}
                relationshipLinks={relationshipLinks}
                relationshipId={relationshipId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Relationships;
