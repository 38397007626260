import { React, useEffect, useState } from "react";
import TableContainer from "../../../components/Molecules/TableContainer";
import AddEditUser from "../../modals/Users/AddEditUser";
import ResetPasswordUser from "../../modals/Users/ResetPasswordUser";

import DeleteUser from "../../modals/Users/DeleteUser";
import ActionBtn from "../../Atoms/ActionBtn";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EditIcon from "../../../assets/images/Users/EditIcon.svg";
import DeleteIcon from "../../../assets/images/Users/DeleteIcon.svg";
import ResetIcon from "../../../assets/images/Users/ResetIcon.svg";

const TableFields = ({
  data,
  tableHeader,
  selectedUser,
  setSelectedUser,
  rowsCount,
  refetchUsers,
  tablePageSize,
  setTablePageSize,
  setTablePage,
  tablePage,
}) => {
  //------------------------------------------------------

  const [addEditMode, setAddEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [resetPasswordMode, setResetPasswordMode] = useState(false);

  //---------------------------------------------------
  useEffect(() => {
    // console.log(data);
  }, []);
  //---------------------------------------------------
  const handleActionClick = () => {
    setAddEditMode(true);
    setSelectedUser(null);
  };
  //---------------------------------------------------
  return (
    <>
      <TableContainer
        tablePageSize={tablePageSize}
        tablePage={tablePage}
        setTablePageSize={setTablePageSize}
        setTablePage={setTablePage}
        data={data}
        tableHeader={tableHeader}
        withActionColumn="Action"
        normalTable
        headerBtns={[
          <ActionBtn
            variant={"primary"}
            title="Add User"
            rounded
            OnClick={handleActionClick}
            classname={"container-50-50-100"}
          />,
          <ActionBtn
            title={rowsCount + " users"}
            rounded
            bg="#DCEEFF"
            classname={"container-20-25-25"}
          />,
        ]}
        actionBtns={[
          <img
            src={EditIcon}
            className="actionIcon"
            alt="Edit"
            onClick={() => {
              setAddEditMode(true);
            }}
          />,
          <img
            src={ResetIcon}
            className="actionIcon"
            alt="Reset"
            onClick={() => setResetPasswordMode(true)}
          />,
          <img
            src={DeleteIcon}
            className="actionIcon"
            alt="Delete"
            onClick={() => setDeleteMode(true)}
          />,
        ]}
        loadingText="Loading Table"
        normalTableName="Users List"
        setSelectedUser={setSelectedUser}
        rowsCount={rowsCount}
      />

      {addEditMode ? (
        <AddEditUser
          selectedUser={selectedUser}
          setAddEditMode={setAddEditMode}
          refetchUsers={refetchUsers}
        />
      ) : null}
      {deleteMode ? (
        <DeleteUser
          selectedUser={selectedUser}
          setDeleteMode={setDeleteMode}
          refetchUsers={refetchUsers}
        />
      ) : null}
      {resetPasswordMode ? (
        <ResetPasswordUser
          selectedUser={selectedUser}
          setResetPasswordMode={setResetPasswordMode}
        />
      ) : null}
    </>
  );
};

export default TableFields;
