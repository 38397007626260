import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Background,
  Controls,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "../../pages/Nodes/CustomNode";

import CustomEdge from "../../pages/Nodes/CustomEdge";
import CustomType from "../../pages/Nodes/CustomType";
import { FunctionsStore } from "../../components/layout/Layout";

import RelationshipModal from "../../components/modals/RelationshipModal";
const nodeTypes = { custom: CustomNode };
const edgeTypes = {
  CustomEdge: CustomEdge,
  "start-end": CustomType,
};

function CreateReports(props) {
  const FindObj = useContext(FunctionsStore)[2];
  const FindIndexInObj = useContext(FunctionsStore)[1];
  const FindDeleteInObj = useContext(FunctionsStore)[4];
  const FindMultipleInObj = useContext(FunctionsStore)[3];
  const [link, setLink] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sourceTable, setSourceTable] = useState({});
  const [targetTable, setTargetTable] = useState({});
  const [random, setRandom] = useState(null);
  const [random1, setRandom1] = useState(null);
  const [random2, setRandom2] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [modalOptions, setModalOptions] = useState([]);

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const onConnect = (params) => {
    setSourceTable(params.source);
    setTargetTable(params.target);
    setLink(params);
    if (
      FindMultipleInObj(
        edges,
        "source",
        params.source,
        "target",
        params.target
      ) ||
      FindMultipleInObj(edges, "target", params.source, "source", params.target)
    ) {
      toast.error("These Two Tables have already been Linked", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setShowModal(true);
      setModalOptions([]);
    }
  };

  //--------------------------
  function FillNames(Tables, linkItem, Type) {
    let name = "";
    for (let i = 0; i < Tables.length; i++) {
      const tableItem = Tables[i];

      if (
        linkItem.sourceTable === tableItem.TableId &&
        FindObj(tableItem.TableBody, "columnId", linkItem.sourceColumn) &&
        Type === "source"
      ) {
        let index = FindIndexInObj(
          tableItem.TableBody,
          "columnId",
          linkItem.sourceColumn
        );
        name = tableItem.TableBody[index].columnName;

        break;
      } else if (
        linkItem.targetTable === tableItem.TableId &&
        FindObj(tableItem.TableBody, "columnId", linkItem.targetColumn) &&
        Type === "target"
      ) {
        let index = FindIndexInObj(
          tableItem.TableBody,
          "columnId",
          linkItem.targetColumn
        );
        name = tableItem.TableBody[index].columnName;
        break;
      }
    }
    return name;
  }
  //****************************************
  useEffect(() => {
    if (props.relationshipLinks) {
      let array = props.relationshipLinks;

      let relationTypeText;
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (element.relationType === 1) {
          relationTypeText = "One to One";
        } else if (element.relationType === 2) {
          relationTypeText = "One to Many";
        } else if (element.relationType === 3) {
          relationTypeText = "Many to One";
        } else if (element.relationType === 4) {
          relationTypeText = "Many to Many";
        }

        let sourceColumnText = FillNames(props.Work_Tables, element, "source");
        let targetColumnText = FillNames(props.Work_Tables, element, "target");
        arr.push({
          sourceColumn: element.sourceColumn,
          targetColumn: element.targetColumn,
          relationType: element.relationType,
          sourceColumnText: sourceColumnText,
          targetColumnText: targetColumnText,
          relationTypeText: relationTypeText,
          sourceTable: element.sourceTable,
          targetTable: element.targetTable,
        });
      }

      SaveAssignmentAlready(arr);
    }
  }, [props.relationshipLinks]);
  //****************************************
  useEffect(() => {
    if (props.relationshipLinks) {
      let array = props.relationshipLinks;

      let relationTypeText;
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (element.relationType === 1) {
          relationTypeText = "One to One";
        } else if (element.relationType === 2) {
          relationTypeText = "One to Many";
        } else if (element.relationType === 3) {
          relationTypeText = "Many to One";
        } else if (element.relationType === 4) {
          relationTypeText = "Many to Many";
        }

        let sourceColumnText = FillNames(props.Work_Tables, element, "source");
        let targetColumnText = FillNames(props.Work_Tables, element, "target");
        arr.push({
          sourceColumn: element.sourceColumn,
          targetColumn: element.targetColumn,
          relationType: element.relationType,
          sourceColumnText: sourceColumnText,
          targetColumnText: targetColumnText,
          relationTypeText: relationTypeText,
          sourceTable: element.sourceTable,
          targetTable: element.targetTable,
        });
      }
    }
  }, [props.Work_Tables]);
  //----------------------------
  useEffect(() => {
    removeRS(random);
  }, [random]);
  //----------------------------

  useEffect(() => {
    deleteNodeById(random1);
  }, [random1]);
  //----------------------------

  useEffect(() => {
    if (random2 && random2.toString().substring(0, 3) === "RS_") {
      focusRS(random2);
      setShowModal(true);
    } else if (random2 && random2.toString().substring(0, 6) === "Focus_") {
      focusRS(random2.substring(6, 200));
    } else if (
      random2 &&
      random2.toString().substring(0, 6) !== "Focus_" &&
      random2.toString().substring(0, 3) !== "RS_"
    ) {
      focusRS(Math.random, true);
    }
  }, [random2]);
  //----------------------------

  useEffect(() => {
    if (!showModal) {
      setRandom2(Math.random);
    }
  }, [showModal]);
  //----------------------------

  function focusRS(id, removeFocus = false) {
    let array = [...edges];
    const nodeList = document.querySelectorAll(".NodeColumn");
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].style.backgroundColor = "#FFF";
      nodeList[i].style.color = "#000";
    }
    if (removeFocus) {
      return false;
    }
    // console.log(array);
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        let Left = document.getElementsByClassName(array[i].sourceColumn)[0];
        let Right = document.getElementsByClassName(array[i].targetColumn)[0];
        Left.style.backgroundColor = "#D1F7ED";
        Left.style.color = "#000";
        Right.style.backgroundColor = "#D1F7ED";
        Right.style.color = "#000";
        setModalOptions([
          { value: array[i].sourceColumn, text: array[i].sourceColumnName },
          { value: array[i].targetColumn, text: array[i].targetColumnName },
          { value: array[i].relationType, text: array[i].relationTypeName },
        ]);
        setSourceTable(array[i].source);
        setTargetTable(array[i].target);
        setLink({ source: array[i].source, target: array[i].target });

        break;
      }
    }
  }
  //----------------------------
  function removeRS(id) {
    let array = [...edges];
    const nodeList = document.querySelectorAll(".NodeColumn");
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].style.backgroundColor = "#FFF";
      nodeList[i].style.color = "#000";
    }
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array.splice(i, 1);
        break;
      }
    }
    setEdges(array);
  }
  //----------------------------
  const deleteNodeById = (id) => {
    let arr = [...nodes];
    let WT = [...props.Work_Tables];
    let array = [...edges];

    for (var i = 0; i < array.length; i++) {
      if (array[i].source === id || array[i].target === id) {
        array.splice(i, 1);
      }
    }
    setEdges(array);
    for (var i = 0; i < WT.length; i++) {
      if (WT[i].TableId === id) {
        WT.splice(i, 1);
        props.setWork_Tables(WT);
        break;
      }
    }
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        arr.splice(i, 1);
        setNodes(arr);
        break;
      }
    }
  };
  //***************************
  useEffect(() => {
    // console.log(edges);
  }, [edges]);
  //----------------------------
  const SaveAssignmentAlready = (Cols, TwoTables = []) => {
    let Ed = [];
    let Link1, Link2;

    //console.log(Cols);
    for (let i = 0; i < Cols.length; i++) {
      const element = Cols[i];
      if (element.relationType === 1) {
        Link1 = "One";
        Link2 = "One";
      } else if (element.relationType === 2) {
        Link1 = "One";
        Link2 = "Many";
      } else if (element.relationType === 3) {
        Link1 = "Many";
        Link2 = "One";
      } else if (element.relationType === 4) {
        Link1 = "Many";
        Link2 = "Many";
      }
      Ed.push({
        id: "RS_" + element.sourceTable + "-" + element.targetTable,
        source: element.sourceTable,
        target: element.targetTable,
        sourceColumn: element.sourceColumn,
        targetColumn: element.targetColumn,
        sourceColumnName: element.sourceColumnText,
        targetColumnName: element.targetColumnText,
        relationType: element.relationType,
        relationTypeName: element.relationTypeText,
        data: {
          startLabel: "start edge label",
          endLabel: "end edge label",
          setRandom: setRandom,
          setRandom2: setRandom2,
          Link1: Link1,
          Link2: Link2,
          sourceColumn: element.sourceColumn,
          targetColumn: element.targetColumn,
        },
        type: "start-end",
      });
    }

    setEdges(Ed);
    setRandom(Math.random);
    setRandom2(Math.random);
  };

  const SaveAssignment = (Cols, TwoTables = []) => {
    let Ed = [...edges];
    let index;
    if (TwoTables.length) {
      index = FindIndexInObj(
        Ed,
        "id",
        "RS_" + TwoTables[0] + "-" + TwoTables[1]
      );
    } else {
      index = FindIndexInObj(Ed, "id", "RS_" + link.source + "-" + link.target);
    }

    if (index !== -1) {
      Ed.splice(index, 1);
    }

    let Link1, Link2;
    if (Cols[2] === 1) {
      Link1 = "One";
      Link2 = "One";
    } else if (Cols[2] === 2) {
      Link1 = "One";
      Link2 = "Many";
    } else if (Cols[2] === 3) {
      Link1 = "Many";
      Link2 = "One";
    } else if (Cols[2] === 4) {
      Link1 = "Many";
      Link2 = "Many";
    }
    //  setEdges((els) => addEdge(link, els));
    //let Ed = [...edges];
    let s, t;
    if (TwoTables.length) {
      s = TwoTables[0];
      t = TwoTables[1];
    } else {
      s = link.source;
      t = link.target;
    }
    let x = "RS_" + s + "-" + t;

    Ed.push({
      id: x,
      source: s,
      target: t,
      sourceColumn: Cols[0],
      targetColumn: Cols[1],
      sourceColumnName: Cols[3],
      targetColumnName: Cols[4],
      relationType: Cols[2],
      relationTypeName: Cols[5],
      data: {
        startLabel: "start edge label",
        endLabel: "end edge label",
        setRandom: setRandom,
        setRandom2: setRandom2,
        Link1: Link1,
        Link2: Link2,
        sourceColumn: Cols[0],
        targetColumn: Cols[1],
      },
      type: "start-end",
    });
    setEdges(Ed);
    setRandom(Math.random);
    setRandom2(Math.random);

    setTimeout(function () {
      setShowModal(false);
    }, 1000);
  };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < edges.length; i++) {
      const element = edges[i];
      arr.push({
        sourceTable: element.source,
        sourceColumn: element.sourceColumn,
        targetTable: element.target,
        targetColumn: element.targetColumn,
        relationType: element.relationType,
      });
    }
    props.setMainJsonBody(arr);
    // console.log(`edges`);
    // console.log(edges);
    // console.log(`edges`);
  }, [edges]);
  //--------------------------------
  useEffect(() => {
    //console.log(props.MainJsonBody);
  }, [props.MainJsonBody]);
  //----------------------------------------------------

  useEffect(() => {
    //  console.log(props.Work_Tables);
    if (!props.Work_Tables.length) {
      setNodes([]);
      return false;
    }
    let aa = [...nodes];
    for (let i = 0; i < props.Work_Tables.length; i++) {
      let X = parseFloat(55);
      let Y = parseFloat(aa.length * 10 + 200);
      const element = props.Work_Tables[i];
      let cols = [element.TableId];
      element.TableBody &&
        element.TableBody.map((el) =>
          cols.push({ id: el.columnId, name: el.columnName })
        );
      if (FindObj(aa, "id", element.TableId)) {
        continue;
      }
      const rndInt = X - i * 150;
      aa.push({
        id: element.TableId,
        data: {
          label: element.TableTitle,
          columns: cols,
          setRandom1: setRandom1,
        },
        sourcePosition: "right",
        targetPosition: "left",
        type: "custom",
        position: { x: rndInt + 150, y: Y },
      });
    }
    setNodes(aa);
    setRandom1(Math.random);
  }, [props.Work_Tables]);
  //----------------------------------------------

  return (
    <div>
      <div>
        <div style={{ height: "800px" }}>
          <RelationshipModal
            show={showModal}
            setShow={setShowModal}
            sourceTable={sourceTable}
            targetTable={targetTable}
            SaveAssignment={SaveAssignment}
            setLink={setLink}
            modalOptions={modalOptions}
            removeRS={removeRS}
            RSID={random2}
          />
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodesDelete={onNodesDelete}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            fitView
            attributionPosition="bottom-left"
            zoomOnScroll={true}
            minZoom="0.6"
            maxZoom="1.3"
          >
            <Background
              id="2"
              gap={10}
              color="#F2F2F2"
              variant={BackgroundVariant.Lines}
            />

            <Controls position="top-right" />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default CreateReports;
