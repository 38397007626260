import { React } from "react";
import Table from "../Molecules/Table";
import "react-toastify/dist/ReactToastify.css";
//---------------------------------------
const TableContainer = (props) => {
  //---------------------------------------

  return (
    // <div>
    //   <div className="row">
    //     <div className="col-12 px-0 ">
    //       <div className={props.withOutBorder ? "card" : null}>
    //         <div className={props.withOutBorder ? "card__body " : null}>
    //           <>
    <Table
      limit={props.tablePageSize}
      tablePage={props.tablePage - 1}
      setTablePageSize={props.setTablePageSize}
      setTablePage={props.setTablePage}
      normalTable={props.normalTable}
      normalTableName={props.normalTableName}
      data={props.data}
      tableHeader={props.tableHeader}
      headerBtns={props.headerBtns}
      actionBtns={props.actionBtns}
      withActionColumn={props.withActionColumn}
      setSelectedUser={props.setSelectedUser}
      rowsCount={props.rowsCount}
    />
    //           </>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default TableContainer;
