import React, { useState, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import rel_arrow from '../../assets/svg/connected.svg'
import header from '../../assets/svg/header.svg'
import bar from '../../assets/svg/bar.svg'
import SelectData from '../table/SelectData';



function CreateReports(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [allLinkArray, setAllLinkArray] = useState([]);

    const [byColumn, setByColumn] = useState(null);
    const [toColumn, setToColumn] = useState(null);
    const [linkTableId, setLinkTableId] = useState(null);

    const [byColumnName, setByColumnName] = useState(null);
    const [toColumnName, setToColumnName] = useState(null);
    const [linkTableName, setLinkTableName] = useState(null);
    
    
    const [refJson, setRefJson] = useState({});
    const [refTable, setRefTable] = useState('');

//----------------------------------------------------
function FindDeleteFromObj(array,Key1,Value1,Key2,Value2 ) {
  for (var i = 0; i < array.length; i++){
      if (array[i][Key1] === Value1  && array[i][Key2] === Value2){
           array.splice(i, 1)
      }
  }  
}  
//----------------------------------------------------
function removeFromRelationship(FromTable,Fromcolumn, ToTable , ToColumn ) {

  let array = [...props.MainJsonBody];
  for (var i = 0; i < array.length; i++){
      if (array[i]['tableId'] === FromTable){
          
              for (var j = 0; j < array[i].reference.length; j++){
                  if (array[i].reference[j]['ByColumn'] === Fromcolumn){

                        FindDeleteFromObj(array[i].reference[j].Target,'targetTableId',ToTable, 'targetColumnId',ToColumn)  
                        
                        if (array[i].reference[j]['Target'].length===0){  array[i].reference.splice(j, 1) } 
                        break
                  }
              } 
              break
              
      }
  }  

  props.setMainJsonBody(array)
}  
//----------------------------------------------------
function getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
}
  
//----------------------------------------------------
useEffect(() => { 




    setLinkTableId(null)
    setToColumn(null)
    setByColumn(null)
    


setAllLinkArray(props.MainJsonBody)


}, [show])  
//----------------------------------------------------


//----------------------------------------------      
function AddLink(Table,obj) {



  if(Table && obj){

    let found = false;
    let array = [...props.MainJsonBody];
    for (var i = 0; i < array.length; i++){
        if (array[i]['tableId'] === Table){
             


                for (var j = 0; j < array[i].reference.length; j++){
                  if (array[i].reference[j]['ByColumn'] === obj.ByColumn){
                    array[i].reference[j].Target.push({"targetTableId": obj.TableId, "targetColumnId":obj.ToColumn, "targetTable": obj.TableName, "targetColumn":obj.ToColumnName})
                    found= true
                    break
                  }
                } 

                if(!found) {  array[i].reference.push(obj) }

                break
        }
      }  
      

      
      props.setMainJsonBody(array)
     // setShow(false)

  }
  else{
         toast.warning('Fill mandatory fields', {position: toast.POSITION.TOP_RIGHT});
  }

}  

//----------------------------------------------------




  return (
    <div>

        <Button className="border-button  mx-2 fs-7 d-flex align-items-center fw-medium rounded-pill" variant="" onClick={handleShow}>
        <i className='edu ic-data-rel me-2 black-fill fs-6 '></i>All Links
        </Button>
      
      <Modal centered size='xl' show={show} onHide={handleClose}>
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold'>All Links in Relationsip</Modal.Title>
          <Button className='main-button w-10 fw-semibold rounded-pill py-2 me-4' variant="" onClick={() => AddLink(refTable,refJson)}>
                           Add
                        </Button>
        </div>
        </Modal.Header>
        <Modal.Body>





        <div className='row'>

                        <div className='col-6 pe-0'>
                              <SelectData
                              MainJsonBody= {props.MainJsonBody}
                              setMainJsonBody={props.setMainJsonBody}
                              setRefJson={setRefJson}
                              setRefTable={setRefTable}
                              Left
                              byColumn={byColumn}
                              setByColumn={setByColumn}
                              byColumnName={byColumnName}
                              setByColumnName={setByColumnName}
                              toColumn={toColumn}
                              setToColumn={setToColumn}
                              setToColumnName={setToColumnName}
                              toColumnName={toColumnName}
                              linkTableId={linkTableId}
                              setLinkTableId={setLinkTableId}                                              
                              setLinkTableName={setLinkTableName}                                              
                              linkTableName={linkTableName}                                              
                              /> 
                        </div>
                        <div className='col-6 d-flex'>
                        <div className='d-flex flex-column justify-content-center'>
                                          <img src = {rel_arrow} className='black-fill mt-5 me-3'/>
                                        
                        </div>
                        <div className='w-100'>
                        <SelectData 
                              MainJsonBody= {props.MainJsonBody}
                              setMainJsonBody={props.setMainJsonBody}
                              setRefJson={setRefJson}
                              setRefTable={setRefTable}
                              Left={false}
                              byColumn={byColumn}
                              setByColumn={setByColumn}
                              byColumnName={byColumnName}
                              setByColumnName={setByColumnName}
                              toColumn={toColumn}
                              setToColumn={setToColumn}
                              setToColumnName={setToColumnName}
                              toColumnName={toColumnName}
                              linkTableId={linkTableId}
                              setLinkTableId={setLinkTableId}                                              
                              setLinkTableName={setLinkTableName}                                              
                              linkTableName={linkTableName}                        
                              /> 
                        </div>
                        </div>
                       
                  </div>


        <div className='row mt-3 overflow-y-auto max-vh-70'>
           
            {allLinkArray && allLinkArray.map((item) => (  
                <div className='col-6'>
                    
                         
                            <>
                            {item.reference && item.reference.map((a) => (
                            <div className='d-flex mt-4 border-bottom border-1 align-items-center'>
                               <div className='w-50 d-flex align-items-center mb-3'>
                               <div className='rel-card w-100 rounded-4 px-0 mb-5 text-truncate me-3 mb-2'>
                                <div className='rel-card_header card_header d-flex align-items-center justify-content-between fw-semibold text-truncate'>
                                <span className='fw-semibold text-truncate fs-7'>{item.tableName}</span>
                                </div>
                                <div className='p-3 pt-2 fw-medium text-truncate fs-7'><img src = {header} className='me-2'/>{a.ByColumnName}</div>
                                </div>
                                <div className=''>
                                  <img src = {bar} className=''/>
                                </div>
                               </div>



                               <div className='w-50 border-start border-2 border-dark-subtle mb-3'>
                                        <>
                                        {a.Target && a.Target.map((z) => ( 
                                             <>
                                            <div className='d-flex align-items-center w-100'>
                                            <div className=''>
                                              <img src = {rel_arrow} className=''/>
                                            </div>   
                                            <div className='rel-card w-100 rounded-4 px-0 mb-5 ms-3 text-truncate'>
                                            <div className='rel-card_header card_header d-flex align-items-center justify-content-between'>
                                            <span className='fw-semibold text-truncate fs-7'>{z.targetTable}</span>
                                            <Button variant='' className='p-0'  onClick={() => removeFromRelationship(item.tableId, a.ByColumn, a.TableId, z.targetColumnId)}><i className='edu ic-close fs-7 white-fill'></i></Button>
                                            </div>
                                            <div className='p-3 pt-2 fw-medium text-truncate fs-7'><img src = {header} className='me-2'/>{z.targetColumn}</div>
                                            </div>
                                            </div>
                                            </>
                                        ))}
                                        </>
                                  
                               
                              </div>
                            </div>
                            ))}
                            </>
                            </div>
                    ))}
                     </div>
                
            

        {/* ))} */}




        </Modal.Body>
        <Modal.Footer className='border-0 row'>
         
        </Modal.Footer>
        </Modal>
    </div>
  )
}

export default CreateReports
