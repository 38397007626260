import React, { useState } from 'react'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import office from '../../../assets/svg/office.svg'
import office_icon from '../../../assets/svg/office-icon.svg'
//import DirectoryFiles from './DirectoryFiles';
import { Link } from 'react-router-dom'

import Office360 from '../../Platforms/Office360'

import GetBaseUrl from '../../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";




function ConnectOffice365(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState(""); 
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Redirect = () => {
      window.location.href='/Office360/Platform/Tables';
    };

    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");



    
    const [ConnectionId, setConnectionId] = useState('');
    const [Connected, setConnected] = useState(false);
    const [Office_ClientID, setOffice_ClientID] = useState('');
    const [Office_TenantID, setOffice_TenantID] = useState('');
    const [Office_ClientSecret, setOffice_ClientSecret] = useState('');
    const [ConnectionName, setConnectionName] = useState('');

    const handleChange = event => {
          if(event.target.name==='Office_ClientID'){
            setOffice_ClientID(event.target.value);
          }
          else if(event.target.name==='Office_TenantID'){
            setOffice_TenantID(event.target.value);
          }
          else if(event.target.name==='Office_ClientSecret'){
            setOffice_ClientSecret(event.target.value);
          }
          else if(event.target.name==='ConnectionName'){
            setConnectionName(event.target.value);
          }
    };   
    const handleConnection = async () => {

      if(!Office_TenantID || !Office_ClientID || !Office_ClientSecret || !ConnectionName){  toast.warning('Fill Required Fields') ; return false; }
      const ToPost = {"platformId": 1, "client_id": Office_ClientID, "tenantId": Office_TenantID, "client_secret": Office_ClientSecret, "connectionName": ConnectionName}; 
      const response = await axios
          .post(BaseUrl+"/Platform/AddOffice365Connection", ToPost, {   
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error));
          if (response.status===200 ) {

                toast.success('Connected successfully', {
                  position: toast.POSITION.TOP_RIGHT
                });
                handleClose();
                setConnected(true)
                setConnectionId(response.data.connectionId)                
          }
          else{
                toast.error('Incorrect credentials', {
                  position: toast.POSITION.TOP_RIGHT
                });
          }
};



  return (
<div className = "rounded-5 main-bg h-100">
  {!Connected ?
    <div className = "top-bg rounded-5 h-100">
        <div className='d-flex flex-column align-items-center w-30'>
                  <div className='d-flex align-items-center w-100 mb-4'>
                  <Link to='/DataInput/Directory'   ><Button variant='' className='p-0 pe-3'><i className='edu ic-left'/></Button></Link>
                  <img src = {office} className='w-50 '/>
                  </div>
                  <div className='w-100'>
                  <Form.Label className='fw-semibold'>Directory Name</Form.Label>
                  <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-4' placeholder="Enter Directory Name" name='ConnectionName' onChange={handleChange} />
                      <Form.Label className='fw-semibold'>Connect to Directory</Form.Label>
                      <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Tenant ID" name='Office_TenantID' onChange={handleChange} />
                      <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client ID" name='Office_ClientID' onChange={handleChange} />
                      <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client Secret" name='Office_ClientSecret' onChange={handleChange}  />
                  <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
                    Connect & Save
                  </Button>
                  </div>
        </div>
    </div>
  :
  <Office360  ConnectionId={ConnectionId} />
  }
 </div>
  )
}

export default ConnectOffice365
