import React, { useState } from 'react'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import canvas from '../../../assets/svg/canvas.svg'
import GetBaseUrl from '../../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Canvas from '../../Platforms/Canvas'
import { Link } from 'react-router-dom'

function ConnectCanvas(props) {
    const [show, setShow] = useState(false); 
    const [value, setValue] = React.useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Redirect = () => {
      window.location.href='/Canvas/Platform/Tables';
    };    
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");


    const [ConnectionId, setConnectionId] = useState('');
    const [Connected, setConnected] = useState(false);

    const [Canvas_ClientID, setCanvas_ClientID] = useState('');
    const [Canvas_RefreshToken, setCanvas_RefreshToken] = useState('');
    const [Canvas_ClientSecret, setCanvas_ClientSecret] = useState('');
    const [Canvas_BaseURL, setCanvas_BaseURL] = useState('');
    const [ConnectionName, setConnectionName] = useState('');
    const handleChange = event => {
          if(event.target.name==='Canvas_ClientID'){
            setCanvas_ClientID(event.target.value);
          }
          else if(event.target.name==='Canvas_RefreshToken'){
            setCanvas_RefreshToken(event.target.value);
          }
          else if(event.target.name==='Canvas_ClientSecret'){
            setCanvas_ClientSecret(event.target.value);
          }
          else if(event.target.name==='Canvas_BaseURL'){
            setCanvas_BaseURL(event.target.value);
          }
          else if(event.target.name==='ConnectionName'){
            setConnectionName(event.target.value);
          }          
    };    
    
    const handleConnection = async () => {
    
          if(!Canvas_ClientID || !Canvas_RefreshToken || !Canvas_ClientSecret  || !Canvas_BaseURL  || !ConnectionName){  toast.warning('Fill Required Fields') ; return false; }
          const ToPost = {"platformId": 2, "client_id": Canvas_ClientID, "refresh_token": Canvas_RefreshToken, "client_secret": Canvas_ClientSecret, "baseurl": Canvas_BaseURL, "connectionName" : ConnectionName}; 
          const response = await axios
              .post(BaseUrl+"/Platform/AddCanvasConnection", ToPost, {   
                headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
                },
              })
              .catch((error) => console.log('Error: ', error));
              if (response.status===200 ) {
    
                    toast.success('Connected successfully', {
                      position: toast.POSITION.TOP_RIGHT
                    });
                    handleClose();
                    setConnected(true)
                setConnectionId(response.data.connectionId)                
              }
              else{
                toast.error('Incorrect credentials', {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
    };

  return (
    <div className = "rounded-5 main-bg h-100">
  {!Connected ?      
    <div className = "top-bg rounded-5 h-100">
    <div className='d-flex flex-column align-items-center w-30'>
    <div className='d-flex align-items-center w-100 mb-4'>
    <Link to='/DataInput/Directory'   >
    <Button variant='' className='p-0 pe-3'><i className='edu ic-left'/></Button>
    </Link>
    <img src = {canvas} className='w-50 '/>
    </div>
    <div className='w-100'>
    <Form.Label className='fw-semibold'>Directory Name</Form.Label>
    <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-4' placeholder="Enter Directory Name"  name='ConnectionName' onChange={handleChange}/>
    <Form.Label className='fw-semibold'>Connect to Directory</Form.Label>
    <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Client ID" name='Canvas_ClientID' onChange={handleChange} />
    <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Refresh Token" name='Canvas_RefreshToken' onChange={handleChange} />
    <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Client Secret" name='Canvas_ClientSecret' onChange={handleChange} />
    <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="Base URL" name='Canvas_BaseURL' onChange={handleChange} />
    <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
      Connect & Save
    </Button>
    
    </div>
</div>
    </div>
    :
  <Canvas  ConnectionId={ConnectionId} />
  }    

    </div>
  )
}

export default ConnectCanvas
