import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  fromValue,
  toValue,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
}) {
  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;
    let canRead = e.target.attributes.rangeAttr;

    if (canRead) {
      let rangeFrom = document.getElementsByClassName("rangeFrom")[0]["value"];
      let rangeTo = document.getElementsByClassName("rangeTo")[0]["value"];
      let range;

      if (rangeFrom && rangeTo) {
        range = rangeFrom + "-" + rangeTo;
      }
      val = range;
    } else {
      val = e.target.value;
    }

    res = name;
    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  return (
    <div className=" ">
      <div className="row d-flex align-items-center">
        <div className="col-5">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">{name}</Form.Label>
            <Form.Control
              className="field-input rangeFrom"
              rangeAttr="rangeFrom"
              type="number"
              placeholder="From"
              defaultValue={fromValue}
              onChange={handleChange}
              name={Id}
            />
          </Form.Group>
        </div>
        <div className="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
          <Form.Label className="fw-semibold fs-7 mb-0 mt-3">-</Form.Label>
        </div>
        <div className="col-5">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold mb-2"></Form.Label>
            <Form.Control
              className="field-input mt-2 rangeTo"
              rangeAttr="rangeTo"
              type="text"
              placeholder="To"
              defaultValue={toValue}
              onChange={handleChange}
              name={Id}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}
export default Component;
