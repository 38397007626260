import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import deletechar from "../../assets/svg/delete-char.svg";
import "./modal.css";
import GetBaseUrl from "../../functions/SetBaseUrl";
import InputRange from "react-input-range";
import upload from "../../assets/svg/upload-file.svg";
import add from "../../assets/svg/add-value.svg";
import delete_value from "../../assets/svg/del-value.svg";
import note from "../../assets/svg/note-sm.svg";
import reset from "../../assets/svg/retry-white.svg";
import DragDropFile from "../UploadDocs/DragDropFile";
import back from "../../assets/svg/back.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { display } from "@mui/system";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ColumnMapping from "./ColumnMapping";

import DateRange from "./FieldPropertiesComponents/DateRange";
import NumberRange from "./FieldPropertiesComponents/NumberRange";
import DataCriteria from "./FieldPropertiesComponents/DataCriteria";
import DataCriteriaRange from "./FieldPropertiesComponents/DataCriteriaRange";
import RadioProp from "./FieldPropertiesComponents/RadioProp";
import CheckboxProp from "./FieldPropertiesComponents/CheckboxProp";
import OptionsProp from "./FieldPropertiesComponents/OptionsProp";
import InputField from "./FieldPropertiesComponents/InputField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["!", "@", "#", "$", "%", "&"];

function FieldProperties(props) {
  const [show, setShow] = useState(true);
  const [value, setValue] = React.useState("");

  const [personName, setPersonName] = React.useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Options, setOptions] = useState([]);
  const [MappingList, setMappingList] = useState([]);
  const [RemoveData, setRemoveData] = useState([]);
  const [AddData, setAddData] = useState([]);

  const [recallCriteria, setRecallCriteria] = React.useState(null);

  const [DefaultMappingList, setDefaultMappingList] = useState([]);

  const [RestrictionsList, setRestrictionsList] = useState([]);

  const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const BaseUrl = GetBaseUrl()[0];

  const [AssignedDataProp, setAssignedDataProp] = useState();
  const [AssignedDataPropForm, setAssignedDataPropForm] = useState([]);

  let range = [];

  const [minCriteriaPlaceholder, setMinCriteriaPlaceholder] = useState("min");
  const [maxCriteriaPlaceholder, setMaxCriteriaPlaceholder] = useState("max");

  const [Duplicates, setDuplicates] = useState(false);
  const [Blanks, setBlanks] = useState(false);
  const [reloadList, setReloadList] = useState(null);
  // const [NewItem, setNewItem] = useState(null);
  const [NewItemRestriction, setNewItemRestriction] = useState(null);

  const [affectedByMapping, setAffectedByMapping] = useState([]);

  const [DateFormat, setDateFormat] = useState([]);
  const [DateTimeFormat, setDateTimeFormat] = useState([]);
  const [TimeFormat, setTimeFormat] = useState([]);

  const handleChangeItemRestriction = (event) => {
    setNewItemRestriction(event.target.value);
  };

  function FormatValues(type) {
    let arr = [];
    axios
      .get(BaseUrl + "/Settings/GetDateTimeFormats/" + type, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data) {
          res.data.map((item) => {
            arr.push(item.format);
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        if (type === "date") {
          setDateFormat(arr);
        } else if (type === "time") {
          setTimeFormat(arr);
        } else if (type === "datetime") {
          setDateTimeFormat(arr);
        }
      });
    //----------------------------------------
  }

  useEffect(() => {
    if (show && !props.ByDataType.length) {
      props.setColumnsOptions([]);
      setAssignedDataPropForm([]);

      axios
        .get(BaseUrl + "/Data/GetDataPropertiesById/" + props.ColumnId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.data) {
            props.AllDataProp[props.ColumnId] = res.data;
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.propertyName === "Characters Restrictions")
                setRestrictionsList(element.propertyValue.split(""));
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          props.setColumnsOptions(props.AllDataProp[props.ColumnId]);
          props.AllDataProp[props.ColumnId] = [];
        });
    } else if (show && props.ByDataType.length) {
      props.setColumnsOptions(props.ByDataType);
      setAssignedDataPropForm([]);
    }

    setReloadList(Math.random(100));

    //--------------------------------------------------
    FormatValues("date");
    FormatValues("time");
    FormatValues("datetime");
    //--------------------------------------------------
  }, [show]);

  //-------------------------------------------------------
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < props.ColumnsOptions.length; i++) {
      const element = props.ColumnsOptions[i];
      if (element.isMapAffected) {
        arr.push(element.dataPropertyId);
      }
    }
    setAffectedByMapping(arr);
  }, [props.ColumnsOptions]);
  //--------------------------------------------------------------
  function SaveFullMapping(array, addData, removeData) {
    let Json = {
      tableId: props.TableId,
      columnId: props.ColumnId,
      data: addData,
      removeData: removeData,
    };
    //console.log(Json);

    axios
      .post(BaseUrl + "/Data/PostMapColumn", Json, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {})
      .catch((res) => {});
  }

  //--------------------------------------------------------------
  function RemoveRestriction(value) {
    let index = RestrictionsList.indexOf(value);
    let arr = [...RestrictionsList];
    arr.splice(index, 1);
    setRestrictionsList(arr);
    document.getElementsByClassName("RestrictionsChar")[0]["value"] = arr
      .toString()
      .replace(",", "");
  }
  //--------------------------------------------------------------

  useEffect(() => {
    setAssignedDataPropForm([]);
  }, [props.ColumnId]);

  useEffect(() => {
    var a = [...AssignedDataPropForm];
    a.push(AssignedDataProp);

    if (!a[0]) {
      a.splice(0, 1);
    }
    setAssignedDataPropForm(a);
  }, [AssignedDataProp]);
  //----------------------------------------------
  useEffect(() => {
    //console.log(props.ColumnsOptions);
  }, [props.ColumnsOptions]);

  useEffect(() => {
    // console.log(RestrictionsList);
  }, [RestrictionsList]);

  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;
    let isChecked = e.target.checked;
    let inputType = e.target.type;

    let canRead = e.target.attributes.rangeAttr;
    let canReadDate = e.target.attributes.rangeDateAttr;
    let canReadCriteria = e.target.attributes.rangeCriteriaAttr;
    if (name === "8") {
      if ([...RestrictionsList].indexOf(e.target.value) === -1) {
        setRestrictionsList([...RestrictionsList, e.target.value]);
      }
    }

    if (name === "2") {
      let EmailValue =
        document.getElementsByClassName("EmailValue")[0]["value"];
      if (EmailValue !== "false") {
        document.querySelectorAll(".Separator").forEach((el) => {
          el.style.display = "initial";
        });
      } else {
        document
          .querySelectorAll(".Separator")
          .forEach((el) => (el.style.display = "none"));
      }
    }

    if (e.target.attributes.LettersRestrict) {
      if (e.target.attributes.LettersRestrict.value !== "") {
        let LettersRestrict =
          e.target.attributes.LettersRestrict.value.split(",");
        let newText = e.target.value;
        e.target.value = newText.replace(
          new RegExp(`[^${LettersRestrict.join("")}]`, "g"),
          ""
        );
        e.target.value = e.target.value.replace("l", "L");
      }
    }

    if (canRead) {
      let rangeFrom = document.getElementsByClassName("rangeFrom")[0]["value"];
      let rangeTo = document.getElementsByClassName("rangeTo")[0]["value"];
      let range;

      if (rangeFrom && rangeTo) {
        range = rangeFrom + "-" + rangeTo;
      }
      val = range;
    } else if (canReadCriteria) {
      let CriteriaType =
        document.getElementsByClassName("CriteriaType")[0]["value"];
      let rangeFrom =
        document.getElementsByClassName("rangeFromCriteria")[0]["value"];
      let rangeTo =
        document.getElementsByClassName("rangeToCriteria")[0]["value"];
      let range;

      let CT;
      if (CriteriaType !== "Inbetween") {
        CT = CriteriaType;
        setMinCriteriaPlaceholder("set Value");
        document.querySelectorAll(".minCriteria").forEach((el) => {
          el.style.display = "initial";
        });
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "none"));
      } else {
        setMinCriteriaPlaceholder("min");
        CT = "";
        document.querySelectorAll(".minCriteria").forEach((el) => {
          el.style.display = "initial";
          el.placeholder = "zzz";
        });
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "initial"));
      }

      if (rangeFrom && rangeTo) {
        range = CT + rangeFrom;
        if (CriteriaType === "Inbetween") {
          range += "<>" + rangeTo;
        }
      }
      if (inputType !== "select-one") {
        val = range;
      } else {
        val = e.target.value;
      }
    } else if (canReadDate) {
      let rangeFrom = document.getElementsByClassName("rangeFrom")[0]["value"];
      let rangeTo = document.getElementsByClassName("rangeTo")[0]["value"];
      let range;

      if (rangeFrom && rangeTo) {
        range = rangeFrom + "-" + rangeTo;
      }
      val = range;
    } else {
      val = e.target.value;
    }

    res = name;
    if (isChecked && inputType === "checkbox") {
      val = true;
    } else if (!isChecked && inputType === "checkbox") {
      val = false;
    }

    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  useEffect(() => {
    // console.log(AssignedDataPropForm);
  }, [AssignedDataPropForm]);
  //----------------------------------------
  function ResetOtherProps(array) {
    //console.log(array);
    let Json = {
      columnId: props.ColumnId,
      dataTypeIds: array,
    };
    // console.log(Json);
    axios
      .post(BaseUrl + "/Data/ResetDataPropertiesById", Json, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        // console.log("ok");
      })
      .catch(() => {})
      .finally(() => {});
  }
  //----------------------------------------
  function FindReturnObj(array, Key, Value) {
    var msg;
    for (var i = array.length - 1; i >= 0; i--) {
      if (array[i][Key] === Value) {
        msg = array[i];
        break;
      }
    }
    return msg;
  }
  //--------------------------

  function saveAssignedDataProp(a1, run) {
    const BaseUrl = GetBaseUrl()[0];
    var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
    // if (AssignedDataPropForm.length === 0) {
    //   toast.error("Select Fields Properties", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return false;
    // }
    // if (!AssignedDataPropForm[0]) {
    //   AssignedDataPropForm.splice(0, 1);
    // }
    // console.log(AssignedDataPropForm);
    // console.log(a1);
    //return false;
    console.log(AssignedDataPropForm);
    let dataTypeId = props.ColumnsOptions[0].dataTypeId;
    if (!dataTypeId) {
      toast.error("No Data Type for this Column", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    try {
      axios({
        method: "post",
        url:
          BaseUrl + "/Data/AssignColumnDataProperties/" + a1 + "/" + dataTypeId,
        data: AssignedDataPropForm,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res.data);
          toast.success("Data Properties have been updated", {
            position: toast.POSITION.TOP_RIGHT,
          });

          //   props.saveAssignedColumns("", true);
          props.setByDataType([]);

          if (MappingList.length) {
            ResetOtherProps(affectedByMapping);
          }
          if (AddData.length || RemoveData.length) {
            SaveFullMapping(MappingList, AddData, RemoveData);
          }

          props.setCurrentColumnDataType([]);
          if (run) {
            toast.info("Validation in Progress", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(function () {
              props.runValidation(props.TableId);
              props.setViewDataFieldPropTable(false);
            }, 5000);
          } else {
            setTimeout(function () {
              props.setViewDataFieldPropTable(false);
            }, 3000);
          }
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        });
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const handleChangeCheckbox = (e) => {
    let isChecked = e.target.checked;
    let name = e.target.name;
    if (isChecked) {
      if (name === "Blanks") {
        setBlanks(true);
      } else if (name === "Duplicates") {
        setDuplicates(true);
      }
    } else {
      if (name === "Blanks") {
        setBlanks(false);
      } else if (name === "Duplicates") {
        setDuplicates(false);
      }
    }
  };

  function Range(Item = "") {
    if (Item) {
      return Item.split("-");
    } else {
      return ["", ""];
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-4 ">
        <div className="d-flex align-items-center  w-40">
          <Button
            className="ps-0 py-0 d-flex align-items-center"
            variant=""
            onClick={() => {
              props.setViewDataFieldsTable(props.TableId);
              props.setViewDataFieldPropTable(false);
              props.CurrentColumnFunc(props.currentColumnDataType);
              // AddMapping(DefaultMappingList);
            }}
          >
            <img src={back} style={{ width: "20px" }} />
            <span
              className="ms-2 fs-6 fw-normal text-truncate"
              style={{ width: "50%" }}
            >
              {props.TableName}
            </span>{" "}
            / <span className="ms-2 fs-6 fw-semibold">{props.ColumnName}</span>
          </Button>
        </div>
        <div className="d-flex w-60 ">
          <Button
            variant=""
            className="main-button  w-75 mx-2 fw-semibold rounded-pill py-2"
            onClick={() => {
              props.setViewConditionalFormatting(true);
              props.setViewDataFieldPropTable(false);
            }}
          >
            Conditional Formatting
          </Button>
          <Button
            variant=""
            className="btn-secondary w-40 fw-semibold rounded-pill py-2"
            onClick={() => {
              props.setViewDataFieldsTable(props.TableId);
              props.setViewDataFieldPropTable(false);
              // AddMapping(DefaultMappingList);
            }}
          >
            Discard
          </Button>
          <Button
            variant=""
            className="main-button w-40 fw-semibold mx-2 rounded-pill py-2"
            onClick={() => saveAssignedDataProp(props.ColumnId, "")}
          >
            Save
          </Button>
          <Button
            variant=""
            className="main-button w-50 fw-semibold rounded-pill py-2"
            onClick={() => saveAssignedDataProp(props.ColumnId, "run")}
          >
            Save & Run
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-5 min-vh-85 field-scroll">
          <div className="mb-2">
            <span className="fw-semibold fs-6 mb-3">Field Properties</span>
          </div>
          <div className="">
            {props.ColumnsOptions &&
              props.ColumnsOptions.map((item) => (
                <>
                  {item.propertyType === "checkbox" ? (
                    <CheckboxProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      radioType={item.dataPropertyId}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "radio" ? (
                    <RadioProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      radioType={item.dataPropertyId}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "Separator" &&
                    item.propertyType === "options" ? (
                    <OptionsProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      options={[",", ";"]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      defaultHide={item.propertyValue ? "" : "hide"}
                      defaultClassName=" Separator "
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "Data Format" &&
                    item.propertyType === "options" ? (
                    <OptionsProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      options={["True-False", "T-F", "Yes-No", "Y-N", "0-1"]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "Date Format" &&
                    item.propertyType === "options" ? (
                    <OptionsProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      options={DateFormat}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "Time Format" &&
                    item.propertyType === "options" ? (
                    <OptionsProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      options={TimeFormat}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "DateTime Format" &&
                    item.propertyType === "options" ? (
                    <OptionsProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      options={DateTimeFormat}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "Characters Restrictions" &&
                    item.propertyType === "options" ? (
                    <>
                      <OptionsProp
                        Id={item.dataPropertyId}
                        name={item.propertyName}
                        value={item.propertyValue}
                        options={["!", "@", "#", "$", "%"]}
                        setAssignedDataProp={setAssignedDataProp}
                        setAssignedDataPropForm={setAssignedDataPropForm}
                        AssignedDataPropForm={AssignedDataPropForm}
                        defaultHide=""
                        defaultClassName=""
                        RestrictionsList={RestrictionsList}
                        setRestrictionsList={setRestrictionsList}
                        optionsType="Restrictions"
                        affected={item.isMapAffected}
                        disabled={
                          (AddData && AddData.length && item.isMapAffected) ||
                          (MappingList &&
                            MappingList.length &&
                            item.isMapAffected)
                            ? "disable-div"
                            : ""
                        }
                      />
                      {RestrictionsList.length ? (
                        <div className="results row p-3">
                          {RestrictionsList.map((item, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center rounded-pill fw-medium fs-7 val_res p-1 px-2 col-4 mb-1 me-1"
                            >
                              <span>{item}</span>
                              <Button
                                variant=""
                                className="px-1"
                                onClick={() => RemoveRestriction(item)}
                              >
                                <img src={delete_value} />
                              </Button>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  ) : item.propertyName === "Contact Format" &&
                    item.propertyType === "options" ? (
                    <OptionsProp
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      options={[
                        "+971 - 52xxxxxxx",
                        "00971 - 52xxxxxxx",
                        "971 - 52xxxxxxx",
                        "052xxxxxxx",
                      ]}
                      optionsValues={["1", "2", "3", "4"]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      defaultHide=""
                      defaultClassName=""
                      optionsType="Contact Format"
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyName === "Data Criteria" &&
                    item.propertyType === "options" ? (
                    <DataCriteria
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      setMinCriteriaPlaceholder={setMinCriteriaPlaceholder}
                      affected={item.isMapAffected}
                      recallCriteria={recallCriteria}
                      setRecallCriteria={setRecallCriteria}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "text" ? (
                    <InputField
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      inputType="text"
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "int" ? (
                    <InputField
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      inputType="number"
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "range" &&
                    item.propertyName === "Data Criteria Value" ? (
                    <DataCriteriaRange
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      value={item.propertyValue}
                      fromValue={Range(item.propertyValue)[0]}
                      toValue={Range(item.propertyValue)[1]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      minCriteriaPlaceholder={minCriteriaPlaceholder}
                      maxCriteriaPlaceholder={maxCriteriaPlaceholder}
                      affected={item.isMapAffected}
                      recallCriteria={recallCriteria}
                      setRecallCriteria={setRecallCriteria}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "range" ? (
                    <NumberRange
                      Id={item.dataPropertyId}
                      name={item.propertyName}
                      fromValue={Range(item.propertyValue)[0]}
                      toValue={Range(item.propertyValue)[1]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                    />
                  ) : item.propertyType === "dateRange" ? (
                    <DateRange
                      Id={item.dataPropertyId}
                      Type={item.propertyType}
                      name={item.propertyName}
                      fromValue={Range(item.propertyValue)[0]}
                      toValue={Range(item.propertyValue)[1]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "timeRange" ? (
                    <DateRange
                      Id={item.dataPropertyId}
                      Type={item.propertyType}
                      name={item.propertyName}
                      fromValue={Range(item.propertyValue)[0]}
                      toValue={Range(item.propertyValue)[1]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : item.propertyType === "datetimeRange" ? (
                    <DateRange
                      Id={item.dataPropertyId}
                      Type={item.propertyType}
                      name={item.propertyName}
                      fromValue={Range(item.propertyValue)[0]}
                      toValue={Range(item.propertyValue)[1]}
                      setAssignedDataProp={setAssignedDataProp}
                      setAssignedDataPropForm={setAssignedDataPropForm}
                      AssignedDataPropForm={AssignedDataPropForm}
                      affected={item.isMapAffected}
                      disabled={
                        (AddData && AddData.length && item.isMapAffected) ||
                        (MappingList &&
                          MappingList.length &&
                          item.isMapAffected)
                          ? "disable-div"
                          : ""
                      }
                    />
                  ) : null}
                </>
              ))}
          </div>
          <div className="mt-4"></div>
        </div>
        <div className="col-1"></div>
        <div className="col-6 min-vh-85 field-scroll">
          <ColumnMapping
            MappingList={MappingList}
            setMappingList={setMappingList}
            TableId={props.TableId}
            ColumnId={props.ColumnId}
            SaveFullMapping={SaveFullMapping}
            RemoveData={RemoveData}
            setRemoveData={setRemoveData}
            AddData={AddData}
            setAddData={setAddData}
            ColumnsOptions={props.ColumnsOptions}
          />
        </div>
        <div className="col-4 min-vh-85 field-scroll">
          <div className=""></div>
        </div>
      </div>
    </div>
  );
}

export default FieldProperties;
