import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import xls from '../../assets/svg/xls_1.svg'
import pdf from '../../assets/svg/pdf.svg'

const QueryHeader = () => {
  
  return (
    <div className='d-flex flex-column justify-content-between mb-3'>
    <div className='mb-2'>
    <div className='d-flex justify-content-between align-items-center'>
    <h5 className='fw-semibold mb-0'>Customer Data</h5>
      <span className='fs-7 mx-1'>Run 20 mins ago</span>
    </div>
    <div className='d-flex align-items-center'>
      <div className='d-flex align-items-center mt-2'>
        <i className='edu ic-sched'/>
        <span className='fw-semibold mx-2 fs-8'>Run Schedule: </span>
        <span className='fs-8'>Every week on Mon at 8:00am</span>
      </div>
    </div>
    </div>
   <div>
   <div className='d-flex justify-content-end'>
    <Button variant='' className='main-button rounded-pill fw-medium fs-8'>Edit Schedule</Button>
    <Dropdown>
      <Dropdown.Toggle variant='' id="dropdown-basic" className='rounded-pill main-button px-3 fw-medium mx-2 fs-8'>
        Export
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" className='p-1 ps-3'><img className='mx-2' src = {xls}/> Xls</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#/action-2" className='p-1 ps-3'><img className='mx-2' src = {pdf}/> Pdf</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Button variant='' className='main-button rounded-pill fw-medium fs-8'>Edit Query</Button>
   </div>

   </div>
   </div>
  )
}

export default QueryHeader
