import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SidebarChild = (props) => {
  const [open, setOpen] = useState(false);
  const active = props.active ? "active" : "";

  return (
    <div>
      <div onClick={() => setOpen(!open)}>
        <div className="sidebar__item">
          <Link to="/NewRelationships">
            <div
              className={`sidebar__item-inner ${active}`}
              onClick={() => props.RouteClick()}
            >
              <i className="edu ic-data-rel"></i>
              <span>All Relationships</span>
            </div>
          </Link>
        </div>
      </div>
      {/* <Collapse in={open} className="collapse-content">
        <ul>
          <li>
            <Link to="/NewRelationships/Create">
              <div className="sidebar__item">
                <div className={`sidebar__item-inner ${active}`}>
                  <i className="edu ic-menu-arrow"></i>
                  <span>New Relationship</span>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </Collapse> */}
    </div>
  );
};

export default SidebarChild;
