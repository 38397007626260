import React from "react";
import FieldProperties from "../components/modals/FieldProperties";
import ConditionalFields from "../components/ConditionalFields";

function Conditonal() {
  return (
    <div className="rounded-5 main-bg p-35 min-vh-85">
      <div className="row">
        <div className="col-lg-12 col-xl-12 px-0 ">
          <div className="top-bg rounded-5 min-vh-100">
            <ConditionalFields />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conditonal;
