import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";

import styles from "./UsersTable.module.scss";
import DeleteUser from "../../modals/Users/DeleteUser";

export default function TableCustomized(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.limit);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(props.data);

  function FindResults(array, Key, Value) {
    let arr = [];
    for (var j = 0; j < array.length; j++) {
      let element = array[j].columnData;
      let len = true;

      for (var i = 0; i < element.length && len; i++) {
        if (
          element[i][Key] &&
          element[i][Key].toLowerCase().includes(Value.toLowerCase())
        ) {
          arr.push(array[j]);
          len = false;
        }
      }

      if (j === array.length - 1) {
        setSearchResults(arr);
      }
    }
  }
  //-------------------------------------------------------
  function FindReportResults(array, Value) {
    // console.log(array);
    // console.log(Object.keys(array[0]));
    let keys = Object.keys(array[0]);
    let arr = [];

    for (var j = 0; j < array.length; j++) {
      let element = array[j];
      let len = true;
      for (let i = 0; i < keys.length && len; i++) {
        if (element[keys[i]].toLowerCase().includes(Value.toLowerCase())) {
          arr.push(array[j]);
          len = false;
        }
      }

      if (j === array.length - 1) {
        setSearchResults(arr);
      }
    }
  }
  //-------------------------------------------------------

  const handleChange = (event) => {
    // console.log(props.bodyData);
    setSearchTerm(event.target.value);
    if (!event.target.value) {
      setSearchResults(props.data);
    } else if (props.ReportTable) {
      setSearchResults([]);
      FindReportResults(props.data, event.target.value);
    } else if (!props.ReportTable) {
      setSearchResults([]);
      FindResults(props.data, "rowData", event.target.value);
    }
  };

  useEffect(() => {}, [searchResults]);

  //const initDataShow = props.limit && props.bodyData ? props.bodyData.slice(0, Number(props.limit)) : props.bodyData
  const initDataShow =
    props.limit && searchResults ? searchResults : searchResults;
  const [dataShow, setDataShow] = useState(initDataShow);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchResults.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    //console.log(dataShow.length);
  }, [page]);

  return (
    <div>
      <div
        className={`${styles["table-wrapper overflow-x-auto px-1 w-100 "]} `}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder="| Search.."
          className={
            props.Internal ? "TableSearchInput" : "TableSearchInput hide1"
          }
        />
        <table
          className={`${styles["table table-bordered mt-4"]} `}
          id={"Table" + props.TableId}
          ref={props.tableRef}
          aria-label="custom pagination table"
        >
          {props.tableHeader ? (
            <thead>
              <tr className="fs-7 fw-semibold">
                {props.tableHeader &&
                  props.tableHeader?.map((item, index) => {
                    return (
                      <th className={`${styles["header-cell"]} `} key={index}>
                        {item.name}{" "}
                      </th>
                    );
                  })}
              </tr>
            </thead>
          ) : null}
          <tbody className="fs-8">
            {props.data?.items?.map((item, index) => (
              <tr key={item.userId}>
                <td>{item.fullName_En}</td>
                <td>{item.fullName_AR}</td>
                <td>{item.email}</td>
                <td>{item.mobile}</td>
                <td>
                  <DeleteUser />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <table className="table table-borderless mt-4 fs-8">
        <tfoot>
          <tr>
            {dataShow && dataShow.length > 0 ? (
              <CustomTablePagination
                rowsPerPageOptions={[
                  5,
                  props.limit !== 10 ? props.limit : 10,
                  25,
                  50,
                  { label: "All", value: -1 },
                ]}
                colSpan={props.headData.length}
                count={searchResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                className="paging-stick"
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

const blue = {
  50: "#F0F7FF",
  200: "#A5D8FF",
  400: "#3399FF",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 30%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
  }
  `
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  `
);
