import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";

function AssignToGroup({
  setShow,
  show,
  sourceTable,
  targetTable,
  SaveAssignment,
  setLink,
  modalOptions = [null, null, null],
  removeRS,
  RSID,
}) {
  const [SourceTableName, setSourceTableName] = useState(null);
  const [TargetTableName, setTargetTableName] = useState(null);
  const [selectedSourceOption, setSelectedSourceOption] = useState(null);

  const [selectedTargetOption, setSelectedTargetOption] = useState(null);
  const [selectedRelationTypeOption, setSelectedRelationTypeOption] =
    useState(null);

  const [sourceDataTypeName, setSourceDataTypeName] = useState(null);
  const [sourceAcceptDuplicates, setSourceAcceptDuplicates] = useState(null);
  const [targetDataTypeName, setTargetDataTypeName] = useState(null);
  const [targetAcceptDuplicates, setTargetAcceptDuplicates] = useState(null);

  const [relationTypes, setRelationTypes] = useState([
    { value: 1, text: "One to One" },
    { value: 2, text: "One to Many" },
    { value: 3, text: "Many to One" },
    { value: 4, text: "Many to Many" },
  ]);

  const handleClose = () => {
    setShow(false);
    setLink(null);
  };
  const handleShow = () => setShow(true);
  const BaseUrl = GetBaseUrl()[0];
  const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [sourceOptions, setSourceOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);
  const handleChange = (event) => {
    setSelectedRelationTypeOption(event);
  };
  const handleChange1 = (event) => {
    // console.log(event.acceptDuplicates);
    setSelectedSourceOption(event);
    setSourceDataTypeName(event.dataTypeName);
    setSourceAcceptDuplicates(event.acceptDuplicates);
  };
  const handleChange2 = (event) => {
    // console.log(event.acceptDuplicates);
    setSelectedTargetOption(event);
    setTargetDataTypeName(event.dataTypeName);
    setTargetAcceptDuplicates(event.acceptDuplicates);
  };

  let InternalId;

  //----------------------------------------------------

  useEffect(() => {
    if (show) {
      InternalId = RSID;
    }

    let arr1 = [];
    axios
      .get(BaseUrl + "/Data/GetColumnsByTable/" + sourceTable, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setSourceTableName(res.data.tableName);
        res.data.columns.map((item) =>
          arr1.push({
            value: item.columnId,
            text: item.columnName,
            dataTypeName: item.dataTypeName,
            acceptDuplicates: item.acceptDuplicates,
          })
        );
      })
      .catch(() => {})
      .finally(() => {
        setSourceOptions(arr1);
      });
    //----------------------
    let arr2 = [];
    axios
      .get(BaseUrl + "/Data/GetColumnsByTable/" + targetTable, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setTargetTableName(res.data.tableName);

        res.data.columns.map((item) =>
          arr2.push({
            value: item.columnId,
            text: item.columnName,
            dataTypeName: item.dataTypeName,
            acceptDuplicates: item.acceptDuplicates,
          })
        );
      })
      .catch(() => {})
      .finally(() => {
        setTargetOptions(arr2);
      });
  }, [show]);
  //----------------------------------------------------

  useEffect(() => {
    if (modalOptions[0] && modalOptions[1] && modalOptions[2]) {
      setSelectedSourceOption(modalOptions[0]);
      setSelectedTargetOption(modalOptions[1]);
      setSelectedRelationTypeOption(modalOptions[2]);
    } else {
      setSelectedSourceOption(null);
      setSelectedTargetOption(null);
      setSelectedRelationTypeOption(null);
    }
  }, [targetOptions]);

  //----------------------------------------------------

  function Validations() {
    if (!selectedSourceOption) {
      toast.error("Source side is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (!selectedTargetOption) {
      toast.error("Target side is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (!selectedRelationTypeOption) {
      toast.error("Relation Type is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (sourceDataTypeName !== targetDataTypeName) {
      toast.error("Source/Target DataType matching error", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else if (
      (selectedRelationTypeOption.value === "3" ||
        selectedRelationTypeOption.value === "4") &&
      !sourceAcceptDuplicates &&
      sourceAcceptDuplicates !== null
    ) {
      toast.error("Source does not accept Duplicate", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else if (
      (selectedRelationTypeOption.value === "2" ||
        selectedRelationTypeOption.value === "4") &&
      !targetAcceptDuplicates &&
      targetAcceptDuplicates !== null
    ) {
      toast.error("Target does not accept Duplicate", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      SaveAssignment([
        selectedSourceOption.value,
        selectedTargetOption.value,
        selectedRelationTypeOption.value,
        selectedSourceOption.text,
        selectedTargetOption.text,
        selectedRelationTypeOption.text,
      ]);
    }
  }
  //----------------------------------------------------
  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="p-3 pb-0" closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold">
              Relationship Details
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7">
              {SourceTableName}
            </Form.Label>
            <Select
              placeholder="Select A Column"
              value={selectedSourceOption}
              options={sourceOptions}
              onChange={handleChange1}
              className="border rounded-pill fs-6 fw-medium mb-2"
              getOptionLabel={(e) => (
                <div
                  className="fs-7 py-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {e.icon}
                  <span className="px-1 py-0" style={{ marginLeft: 5 }}>
                    {e.text}
                  </span>
                </div>
              )}
            />

            <Form.Label className="fw-semibold fs-7">
              {TargetTableName}
            </Form.Label>
            <Select
              placeholder="Select A Column"
              value={selectedTargetOption}
              options={targetOptions}
              onChange={handleChange2}
              className="border rounded-pill fs-6 fw-medium mb-2"
              getOptionLabel={(e) => (
                <div
                  className="fs-7 py-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {e.icon}
                  <span className="px-1 py-0" style={{ marginLeft: 5 }}>
                    {e.text}
                  </span>
                </div>
              )}
            />

            <Form.Label className="fw-semibold fs-7">Relation Type</Form.Label>
            <Select
              placeholder="Select Relation Type"
              value={selectedRelationTypeOption}
              options={relationTypes}
              onChange={handleChange}
              className="border rounded-pill fs-6 fw-medium mb-2"
              getOptionLabel={(e) => (
                <div
                  className="fs-7 py-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {e.icon}
                  <span className="px-1 py-0" style={{ marginLeft: 5 }}>
                    {e.text}
                  </span>
                </div>
              )}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <Button
            className="main-button w-30 fw-semibold rounded-pill py-2"
            variant=""
            onClick={() => {
              Validations();
            }}
          >
            Save
          </Button>

          <Button
            className="btn-danger w-30 fw-semibold rounded-pill py-2"
            variant=""
            onClick={() => {
              handleClose();
            }}
          >
            Discard
          </Button>
          {selectedSourceOption &&
          selectedTargetOption &&
          selectedRelationTypeOption ? (
            <Button
              className="btn-outline-danger w-30 fw-semibold rounded-pill py-2"
              variant=""
              onClick={() => {
                removeRS("RS_" + sourceTable + "-" + targetTable);
                handleClose();
              }}
            >
              Remove
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AssignToGroup;
