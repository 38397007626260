import React from 'react'
import PublicTable from '../API_Table/main'


const TableHead = [
    'School Code',
    'English Name',
    'School Abbreviation',
  ]
  const BodyCol = [
    'code',
    'englishName',
    'abbreviation',
  ]
  const AllowEdit = [1,2]
  const BaseUrl = localStorage.getItem('Edu_BaseUrl')  +  '/api/ExactPath/GetMultipleSchoolsByOperators/0';

const Result = () => {
  return (
 
       <PublicTable
        loadingText='Loading Table'
        TableTitle='Table of schools'
        TableUrl= {BaseUrl}  
        TableHead= {TableHead}     
        BodyCol=  {BodyCol}
        AllowEdit = {AllowEdit}
        lastColumn={false}
        withOutBorder={false}
        defaultRows='15'
        withHeader
        />

   )
}

export default Result
