import React, { useState } from 'react'
import TableXls from '../tablelist/TableXls'
import TableBb from '../tablelist/TableBb'
import TableGroup from '../tablelist/TableGroup'
import DataType from './DataType'
import Groups from '../tablelist/Groups'
import Table from '../tablelist/Table'
import OutputTable from './OutputTable'
import OutputGroups from './OutputGroups'

import { Button, ButtonGroup, Collapse, Dropdown } from 'react-bootstrap'
import OutputLink from './OutputLink';
import OutputXls from './OutputXls';
import Relation from './Relation';

const OutputTableList = (props) => { 
  const [open, setOpen] = useState(false);


  return (
   <div>
     <div className=' d-flex flex-column justify-content-between h-100 '>
      <div className='mb-5'>


          <div>
          <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='d-flex align-items-center'>
                        <span className='fs-7 fw-medium text-dark third'>RELATIONSHIPS</span>
                    </div>
                </div>

          <div className=''>
          {props.tablesList && props.tablesList.map((item, index) =>
            <Relation
            
            key={index}
            Id= {item.relationshipId}
            Title={item.relationshipName}
            setViewReport={props.setViewReport}
            setObj={props.setObj}
            obj={props.obj}
            setTitle={props.setTitle}
            setChangeColumns={props.setChangeColumns}
            newName={props.newName}

            />
          )}

          </div>
        </div>



      </div>
    </div>
   </div>
  )
}

export default OutputTableList
