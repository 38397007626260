import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  radioType = "",
  affected,
  disabled,
}) {
  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;
    // console.log(e.target.value);
    if (radioType === 2) {
      let EmailValue = e.target.value;
      if (EmailValue === "Yes") {
        document.querySelectorAll(".Separator").forEach((el) => {
          el.style.display = "initial";
        });
      } else {
        document.querySelectorAll(".Separator").forEach((el) => {
          el.style.display = "none";
          el.selectedIndex = 0;
        });
      }
    }

    res = name;
    val = e.target.value;
    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  return (
    <div className={disabled}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="fw-semibold fs-7 mb-0">
          {name === "Even,Odd"
            ? "Number Type"
            : Id === 2 // EmailtypeId
            ? "Email"
            : "..."}
        </Form.Label>
        <div className="d-flex justify-content-start align-items-center">
          {name.split(",").map((name) => (
            <Form.Check
              label={name}
              name={Id}
              className="me-2"
              onChange={handleChange}
              type="radio"
              defaultChecked={value === name ? "checked" : ""}
              value={name}
            />
          ))}

          {name === "Even,Odd" ? (
            <Form.Check
              label={"No Selection"}
              name={Id}
              className="me-2"
              onChange={handleChange}
              type="radio"
              defaultChecked={value === "0" ? "checked" : ""}
              value={"0"}
            />
          ) : null}

          {name === "Yes,No" ? (
            <Form.Check
              label={"No Selection"}
              name={Id}
              className="me-2"
              onChange={handleChange}
              type="radio"
              defaultChecked={value === "0" ? "checked" : ""}
              value={"0"}
            />
          ) : null}
        </div>
      </Form.Group>
    </div>
  );
}
export default Component;
