import React, { useCallback } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Background,
  ConnectionMode,
} from 'reactflow';
import 'reactflow/dist/style.css';

import CustomEdge from './Nodes/CustomEdge';
import CustomType from './Nodes/CustomType';

const initialNodes= [
  {
    id: '3',
    type: 'input',
    data: { label: 'Button Edge 1' },
    position: { x: 125, y: 0 },
  },
  { id: '4', data: { label: 'Button Edge 2' }, position: { x: 125, y: 200 } }
];

const initialEdges = [
    {
        id: 'e3-4',
        source: '3',
        target: '4',
        data: {
          startLabel: 'start edge label',
          endLabel: 'end edge label',
        },
        type: 'start-end',
      },
];

const edgeTypes = {
  CustomEdge: CustomEdge,
  'start-end': CustomType,
};



const EdgesFlow = () => {
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  // const deleteNodeById = (id) => {
  //   setNodes(nds => nds.filter(node => node.id !== id));
  // };
  
  return (
    <div  className='row col-12' style={{"height":"800px"}}>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      snapToGrid={true}
      edgeTypes={edgeTypes}
      fitView
      attributionPosition="top-right"
      connectionMode={ConnectionMode.Loose}
    >
      <Controls />
      <Background />
    </ReactFlow>
    </div>
  );
};

export default EdgesFlow;
