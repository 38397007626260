import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  setMinCriteriaPlaceholder,
  affected,
  recallCriteria,
  setRecallCriteria,
  disabled,
}) {
  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;
    let canReadCriteria = e.target.attributes.rangeCriteriaAttr;
    let inputType = e.target.type;
    // console.log(e.target);
    if (canReadCriteria) {
      let CriteriaType =
        document.getElementsByClassName("CriteriaType")[0]["value"];
      let rangeFrom =
        document.getElementsByClassName("rangeFromCriteria")[0]["value"];
      let rangeTo =
        document.getElementsByClassName("rangeToCriteria")[0]["value"];
      let range;

      let CT;
      if (CriteriaType !== "Inbetween") {
        if (CriteriaType === "Select") {
          document
            .querySelectorAll(".minCriteria")
            .forEach((el) => (el.style.display = "none"));
          document
            .querySelectorAll(".maxCriteria")
            .forEach((el) => (el.style.display = "none"));
          setRecallCriteria("none");
          return false;
        }
        CT = CriteriaType;
        setMinCriteriaPlaceholder("set Value");
        document.querySelectorAll(".minCriteria").forEach((el) => {
          el.style.display = "initial";
        });
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "none"));
      } else if (CriteriaType === "Inbetween") {
        setMinCriteriaPlaceholder("min");
        CT = "";
        document.querySelectorAll(".minCriteria").forEach((el) => {
          el.style.display = "initial";
        });
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "initial"));
      }
    }

    res = name;
    if (res) {
      setAssignedDataProp({
        dataPropertyId: res,
        propertyValue: e.target.value.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === e.target.name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }

    setRecallCriteria(e.target.value);
  };

  //---------------------------------------------------
  useEffect(() => {
    let CriteriaType =
      document.getElementsByClassName("CriteriaType")[0]["value"];
    if (CriteriaType !== "Inbetween") {
      if (CriteriaType === "Select") {
        document
          .querySelectorAll(".minCriteria")
          .forEach((el) => (el.style.display = "none"));
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "none"));
        return false;
      }
      setMinCriteriaPlaceholder("set Value");
      document.querySelectorAll(".minCriteria").forEach((el) => {
        el.style.display = "initial";
      });
      document
        .querySelectorAll(".maxCriteria")
        .forEach((el) => (el.style.display = "none"));
    } else if (CriteriaType === "Inbetween") {
      setMinCriteriaPlaceholder("min");
      document.querySelectorAll(".minCriteria").forEach((el) => {
        el.style.display = "initial";
      });
      document
        .querySelectorAll(".maxCriteria")
        .forEach((el) => (el.style.display = "initial"));
    }

    setRecallCriteria(value);
  }, []);

  return (
    <div className={disabled}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="fw-semibold fs-7 mb-0">{name}</Form.Label>
        <Form.Select
          className="field-input mb-2 CriteriaType"
          rangeCriteriaAttr="Data Criteria"
          aria-label="Default select example "
          onChange={handleChange}
          name={Id}
        >
          <option selected={!value && value !== null ? "selected" : ""}>
            Select
          </option>
          <option value=">" selected={value === ">" ? "selected" : ""}>
            Greater Than
          </option>
          <option value="<" selected={value === "<" ? "selected" : ""}>
            Less Than
          </option>
          <option value="=" selected={value === "=" ? "selected" : ""}>
            Equal
          </option>
          <option
            value="Inbetween"
            selected={value === "Inbetween" ? "selected" : ""}
          >
            Inbetween
          </option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}
export default Component;
