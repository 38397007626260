import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import delete_gif from "../../assets/gif/delete.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import delete_value from "../../assets/svg/del-value.svg";
import reset from "../../assets/svg/retry-white.svg";
import add from "../../assets/svg/add-value.svg";
import upload from "../../assets/svg/upload-file.svg";

import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";
import * as XLSX from "xlsx";
import NormalMappingModal from "./NormalMappingModal";

function Delete({
  MappingList,
  TableId,
  ColumnId,
  setMappingList,
  setRemoveData,
  RemoveData,
  ColumnsOptions,
  setAddData,
  AddData,
}) {
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const [NewItem, setNewItem] = useState(null);
  const [data, setData] = useState([]);

  const [showMappingModal, setShowMappingModal] = useState(false);

  const handleChangeItem = (event) => {
    setNewItem(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      NewItem &&
      [...MappingList].indexOf(NewItem) === -1
    ) {
      setAddData([...AddData, NewItem.trim()]);
      setNewItem("");
    }
  };
  //--------------------------------------------------------------
  function AddMapping(value) {
    if (value && [...MappingList].indexOf(value) === -1) {
      setAddData([...AddData, value.trim()]);
      setNewItem("");
    }
  }
  //--------------------------------------------------------------
  const handleFileUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setData(dataParse);
    };
    reader.readAsBinaryString(f);
  };
  //---------------------------------------------------
  function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
      return !pos || item != ary[pos - 1];
    });
  }
  //-----------------------------
  useEffect(() => {
    // console.log(mappingValues);
    if (data && data.length) {
      let map = [...AddData];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        map.push(element[0]);
      }

      setAddData(uniq(map));
      document.getElementsByClassName("NormalMappingFile")[0].value = "";
    }
  }, [data]);
  //--------------------------------------------------------------
  function ResetAll(params) {
    let arr = [...MappingList];
    setRemoveData(arr);
    setMappingList([]);
  }
  //--------------------------------------------------------------

  function RemoveMapping(value) {
    let arr = [...MappingList];
    let index = MappingList.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
      setRemoveData([...RemoveData, value]);
    }
    setMappingList(arr);
    //-*****************************
    let arr1 = [...AddData];
    let index1 = AddData.indexOf(value);
    if (index1 > -1) {
      arr1.splice(index1, 1);
      setRemoveData([...RemoveData, value]);
    }
    setAddData(arr1);
  }
  //--------------------------------------------------------------Default Mapping

  useEffect(() => {
    setRemoveData([]);
    setAddData([]);

    // let arr = [];
    // axios
    //   .get(BaseUrl + "/Data/GetMapColumn/" + TableId + "/" + ColumnId, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "content-type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     if (res.data) {
    //       if (res.data !== "Not Found") {
    //         res.data && res.data.map((i) => arr.push(i.mappedData));
    //         setMappingList(arr);
    //       }
    //     }
    //   })
    //   .catch(() => {})
    //   .finally(() => {
    //     if (arr.length) {
    //       setMappingList(arr);
    //     } else {
    //       setMappingList([]);
    //     }
    //   });
  }, [ColumnsOptions]);
  //--------------------------------------------
  useEffect(() => {
    if (showMappingModal) {
      let arr = [];
      axios
        .get(BaseUrl + "/Data/GetMapColumn/" + TableId + "/" + ColumnId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.data) {
            if (res.data !== "Not Found") {
              res.data && res.data.map((i) => arr.push(i.mappedData));
              setMappingList(arr);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          if (arr.length) {
            setMappingList(arr);
          } else {
            setMappingList([]);
          }
        });
    }
  }, [showMappingModal]);

  useEffect(() => {
    // console.log(AddData);
  }, [AddData]);
  //---------------------------------------------------

  return (
    <div className="border-top1 border-bottom1 py-0">
      <div className="">
        <span className="fw-semibold fs-6 mb-0">Data Mapping</span>
        <Button
          className=" d-flex align-items-center mt-2 mb-4 px-0 row"
          onClick={() =>
            document.getElementsByClassName("NormalMappingFile")[0].click()
          }
        >
          <img src={upload} className="w-15" />
          <span className="ms-2 fw-semibold fs-7 w-80">
            Upload file with accepted values (.xls)
          </span>
          <Form.Control
            className="field-input NormalMappingFile hide"
            type="file"
            placeholder="Enter Values"
            onChange={handleFileUpload}
          />
        </Button>
      </div>
      <div className="">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="fw-semibold mb-0 fs-7">
            Add value manually
            <Button
              className="btn btn-sm float-right rounded-pill"
              variant="danger"
              onClick={() => ResetAll()}
            >
              <img src={reset} /> Reset
            </Button>
            <NormalMappingModal
              mappingValues={[...MappingList, ...AddData]}
              showOnly
              AddData={AddData}
              setAddData={setAddData}
              setMappingValues={setMappingList}
              RemoveMapping={RemoveMapping}
              setShowMappingModal={setShowMappingModal}
            />
          </Form.Label>
          <div className="inputWithButton">
            <Form.Control
              className="field-input"
              type="text"
              placeholder="Enter Values"
              value={NewItem}
              onChange={handleChangeItem}
              onKeyDown={handleKeyDown}
            />
            <Button variant="" onClick={() => AddMapping(NewItem)}>
              <img src={add} />
            </Button>
          </div>
        </Form.Group>
      </div>
      {MappingList.length || AddData.length ? (
        <div className="results row">
          {MappingList.map((item, index) => (
            <div
              key={index}
              className="d-flex align-items-center rounded-pill fw-medium fs-7 val_res p-1 px-2 col-4 mb-1 me-1"
            >
              <span>{item}</span>
              <Button
                variant=""
                className="px-1"
                onClick={() => RemoveMapping(item)}
              >
                <img src={delete_value} />
              </Button>
            </div>
          ))}

          {AddData.map((item, index) => (
            <div
              key={index}
              className="d-flex align-items-center rounded-pill fw-medium fs-7 val_res p-1 px-2 col-4 mb-1 me-1"
            >
              <span>{item}</span>
              <Button
                variant=""
                className="px-1"
                onClick={() => RemoveMapping(item)}
              >
                <img src={delete_value} />
              </Button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default Delete;
