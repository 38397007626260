import React, { useState } from 'react'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import blackboard from '../../assets/svg/blackboard.svg'
import bb_icon from '../../assets/svg/blackboard-icon.svg'
import DirectoryFiles from './DirectoryFiles';
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function Blackboard(props) {

  
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState(""); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Redirect = () => {
      window.location.href='/BlackBoard/Platform/Tables';
    };
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");


const [Bb_Username, setBb_Username] = useState('');
const [Bb_Password, setBb_Password] = useState('');
const [Bb_BaseURL, setBb_BaseURL] = useState('');
const handleChange = event => {
  if(event.target.name==='Bb_Username'){
    setBb_Username(event.target.value);
  }
  else if(event.target.name==='Bb_Password'){
    setBb_Password(event.target.value);
  }
  else if(event.target.name==='Bb_BaseURL'){
    setBb_BaseURL(event.target.value);
  }

};    

const handleConnection = async () => {

      if(!Bb_Username || !Bb_Password || !Bb_BaseURL){  toast.warning('Fill Required Fields') ; return false; }
      const ToPost = {"platformId": 3, "applicationId": Bb_Username, "secretKey": Bb_Password, "baseurl": Bb_BaseURL}; 
      const response = await axios
          .patch(BaseUrl+"/Platform/UpdateBlackboardConnection/"+props.ConnectionId, ToPost, {   
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error));
          if (response.data===true && response.status===200 ) {

                toast.success('Updated successfully', {
                  position: toast.POSITION.TOP_RIGHT
                });
                handleClose();
                props.setReloadTable(Math.random())
              //  window.location.href='/DataInput/Directory'
          }
          else{
                toast.error('Incorrect credentials', {
                  position: toast.POSITION.TOP_RIGHT
                });
          }          
};



  return (

<>
{props.OnlyButton ?    
          <div>
              <Button className='rounded-pill fw-semibold fs-7 mx-1' variant="primary" onClick={handleShow}> 
                Update Connection
              </Button>
              <Modal centered show={show} onHide={handleClose}>
              <Modal.Header className='p-3 pb-0' closeButton>
              </Modal.Header>
              <Modal.Body className='px-4'>
                  <div className='d-flex flex-column align-items-center w-100'>
                      <img src = {blackboard} className='w-50 mb-4'/>
                      <div className='w-100'>
                      <Form.Control type="text"  className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Application Id" name='Bb_Username' onChange={handleChange} />
                      <Form.Control type="text"   className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="secretKey" name='Bb_Password' onChange={handleChange} />
                      <Form.Control type="text"   className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="BaseURL" name='Bb_BaseURL' onChange={handleChange} />
                      <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
                        Link Account
                      </Button>
                      
                      </div>
                  </div>
              </Modal.Body>
            </Modal>
         </div>

:  
          <div>
          <Button className='p-0' variant="" onClick= {props.IsConnected ? Redirect : handleShow }>
                        <div className='d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3'>
                          <div className='rounded-2 bg-body-secondary w-20 p-3 mb-2'>
                            <img src={bb_icon}/>
                          </div>
                          <span className='fs-5 fw-semibold mb-1'>Blackboard</span>
                          <span className='fs-7 text-start'>A leading global educational technology solution.</span>
                        </div>
          </Button>
          <Modal centered show={show} onHide={handleClose}>
              <Modal.Header className='p-3 pb-0' closeButton>
              </Modal.Header>
              <Modal.Body className='px-4'>
                  <div className='d-flex flex-column align-items-center w-100'>
                      <img src = {blackboard} className='w-50 mb-4'/>
                      <div className='w-100'>
                      <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Application Id" name='Bb_Username' onChange={handleChange} />
                      <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="secretKey" name='Bb_Password' onChange={handleChange} />
                      <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="BaseURL" name='Bb_BaseURL' onChange={handleChange} />
                      <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
                        Link Account
                      </Button>
                      
                      </div>
                  </div>
              </Modal.Body>
          </Modal>
          </div>
}
</>
  )
}

export default Blackboard
